import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_EVENTS_DATA = "GET_EVENTS_DATA";
export const GET_EVENT_CENTER_AGGS = "GET_EVENT_CENTER_AGGS";
export const SET_EVENT_CENTER_FILTERS = "SET_EVENT_CENTER_FILTERS";

const getEventsData = (payload = {}) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const res = await requestWrapper.sendRequest(
            `${BACKEND_URL}/event-center`,
            "POST",
            payload,
            true,
            null,
        );
        let data = {};
        if (res?.ok) {
            data = await res.json();
        }
        dispatch({ type: GET_EVENTS_DATA, payload: data });
    });
};
const getEventCenterAggs = (payload = {}) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const res = await requestWrapper.sendRequest(
            `${BACKEND_URL}/event-center/filters`,
            "POST",
            payload,
            true,
            null,
        );
        let data = {};
        if (res?.ok) {
            data = await res.json();
        }
        dispatch({ type: GET_EVENT_CENTER_AGGS, payload: data });
    });
}
const setEventCenterFilters = (payload = {}) => {
    return action(async (dispatch) => {
        dispatch({ type: SET_EVENT_CENTER_FILTERS, payload });
    });
};
const clearData = () => {
    return action(async (dispatch) => {
        dispatch({ type: GET_EVENTS_DATA, payload: [] });
    });
};
export { getEventsData, getEventCenterAggs, setEventCenterFilters, clearData };
