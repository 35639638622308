
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";

const TIME_FILTERS = ["24h", "3d", "7d"];
const getTimestampByTimeFilter = (timeFilter) => {
    const now = new Date();
    const timestamp = now.getTime();
    switch (timeFilter) {
        case "24h":
            return timestamp - 24 * 60 * 60 * 1000;
        case "3d":
            return timestamp - 3 * 24 * 60 * 60 * 1000;
        case "7d":
            return timestamp - 7 * 24 * 60 * 60 * 1000;
        case "70d":
            return timestamp - 70 * 24 * 60 * 60 * 1000;
        default:
            return timestamp;
    }
};
const handleOpenAssetInInventory = (e, eventItem = {}) => {
    e.stopPropagation();
    const { integrationId = "", assetType = "", assetId = "" } = eventItem;
    const [_, ...rest] = assetId.split(":") || [];
    const assetIdPart = rest.join(":") || "";
    const assetParam = `${integrationId}-${assetType}-${assetIdPart}`;
    const url = `${window.origin}/inventory?asset=${assetParam}&tab=history`;
    window.open(url, "_blank");
}
const handleSetComapreBlock = (obj) => {
    if (isObject(obj)) {
      const str = JSON.stringify(obj, null, 1);
      return str.slice(1, str.length - 1);
    }
    if (isEmpty(obj)) {
      return "";
    }
    return obj;
  };

export {
    TIME_FILTERS,
    handleOpenAssetInInventory,
    getTimestampByTimeFilter,
    handleSetComapreBlock
}