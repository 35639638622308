import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import filter from "lodash/filter";
import {
  getInventoryAggregationsV2,
  setFeatchingType,
} from "../../redux/actions/inventoryActions";
import Base64 from "../../utils/base64";
import { appToast } from "../../shared/appToast/appToast";
import {
  createExclusion,
  testExclusionRule,
  resetTestResult,
} from "../../redux/actions/exclusionsActions";

import { updateExclusion } from "../../redux/actions/exclusionsActions";

// components
import AppWizard from "../../shared/appWizard/appWizard";
import PolicyCode from "./policyCode";
import ExclutionConfiguration from "./exclutionConfiguration";
import SuccessBox from "../../shared/successBox/successBox";

import iacIgnoredIcon from "../../Images/states/iacIgnored-white.svg";
import "./exclutionPolicyCreation.scss";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

const ExclutionPolicyCreation = () => {
  const { user } = useAuth0();
  const { t } = useTranslation('excluded-assets')
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const scopes = useSelector((state) => state.classificationsReducer.scopes);

  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingRego, setLoadingRego] = useState(false);
  const [filterPage, setFilterPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [assetTypes, setAssetTypes] = useState([]);
  const [labels, setLabels] = useState(location?.state?.policy?.labels || []);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableScopeSelection, setDisableScopeSelection] = useState(false);
  const [tablePageSize, setTablePageSize] = useState(50);

  useEffect(() => {
    if (!_.isEmpty(location?.state?.policy)) {
      const policy = location?.state?.policy;
      setIsEditMode(true);
      const newPolicyState = { ...wizardState };
      newPolicyState.name = policy.name;
      newPolicyState.description = policy.description;
      newPolicyState.code = Base64.decode(policy.rego);
      newPolicyState.type = policy.type;
      newPolicyState.isEnabled = policy.isEnabled;
      setWizardState(newPolicyState);
    }
  }, [location?.state?.policy]);

  useEffect(() => {
    dispatch(setFeatchingType("ALL"));
    if (!_.isEmpty(assetTypes)) {
      fetchAssets();
    }
    if (assetTypes?.includes("awsobjects")) {
      setDisableScopeSelection(true);
    } else {
      setDisableScopeSelection(false);
    }
    // only needed on the first query. after that the aggregation needs to stay static on each filter change.
  }, [filterPage, searchVal, assetTypes, tablePageSize]);

  useEffect(() => {
    dispatch(resetTestResult());
  }, []);

  const fetchAssets = async (meta = false) => {
    setLoadingAssets(true);
    await dispatch(
      getInventoryAggregationsV2(
        _.isEmpty(assetTypes) ? [] : _.flatten(formatAssetTypeSelection(assetTypes)),
        null,
        null,
        null,
        null,
        searchVal,
        filterPage,
        true,
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        null,
        true,
        meta ? "meta" : "inv",
        true,
        tablePageSize
      )
    );
    setLoadingAssets(false);
  };

  const formatAssetTypeSelection = (typesArr) => {
    if (typesArr && !_.isEmpty(scopes)) {
      switch (typesArr) {
        case "awsobjects":
          return filter(scopes, (type) => type.startsWith("aws_"));
        case "k8sobjects":
          return filter(scopes, (type) => type.startsWith("kubernetes_"));
        case "akamaiobjects":
          return filter(scopes, (type) => type.startsWith("akamai_"));
        case "datadogobjects":
          return filter(scopes, (type) => type.startsWith("datadog_"));
        case "githubobjects":
          return filter(scopes, (type) => type.startsWith("github_"));
        case "gcpobjects":
          return filter(scopes, (type) => type.startsWith("google_"));
        case "newrelicobjects":
          return filter(scopes, (type) => type.startsWith("newrelic_"));
        case "cloudflareobjects":
          return filter(scopes, (type) => type.startsWith("cloudflare_"));
        case "ns1objects":
          return filter(scopes, (type) => type.startsWith("ns1_"));
        case "mongodbatlasobjects":
          return filter(scopes, (type) => type.startsWith("mongodbatlas_"));
        case "pagerdutyobjects":
          return filter(scopes, (type) => type.startsWith("pagerduty"));
        case "vaultobjects":
          return filter(scopes, (type) => type.startsWith("vault_"));
        default:
          return typesArr;
      }
    }
  };

  const testRegoPolicy = async (returnReq, notify) => {
    setLoadingRego(true);
    const code = Base64.encode(
      (wizardState?.code || "").replace(wizardState?.initialCode || "", "") ||
      ""
    );
    const excludedAssets = await dispatch(
      testExclusionRule(
        wizardState.name,
        wizardState.description,
        code,
        assetTypes,
        [],
        wizardState.unknowns || [],
        notify,
      )
    );

    if (!excludedAssets?.req?.ok) {
      setLoadingRego(false);
      setLoadingSubmit(false);
      return;
    }
    const excludedArns = _.map(excludedAssets.result || [], (asset) => asset.arn);
    setLoadingRego(false);
    setLoadingSubmit(false);
    setWizardState({ ...wizardState, excludedArns });
    if (returnReq) {
      return excludedAssets.req;
    }
  };

  const configWizard = [
    {
      step_title: t('exclusion-creation.steps.details.title'),
      step_description: t('exclusion-creation.steps.details.description'),
      content_title: isEditMode
      ? t('exclusion-creation.steps.details.content-title_edit')
      : t('exclusion-creation.steps.details.content-title_new'),
      content: (
        <ExclutionConfiguration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          setLabels={(labels) => setLabels(labels)}
          allLabels={location?.state?.labels || []}
          currentSelectedLabels={labels}
        />
      ),
      valid: true,
    },
    {
      step_title: t('exclusion-creation.steps.coding.title'),
      step_description: t('exclusion-creation.steps.coding.description'),
      content_title: t('exclusion-creation.steps.coding.content-title'),
      content: (
        <PolicyCode
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          tableLoading={loadingAssets}
          editorLoading={loadingRego}
          setFilterPage={setFilterPage}
          setSearchVal={setSearchVal}
          setAssetTypes={setAssetTypes}
          assetTypes={assetTypes}
          setScopeAfterFetch={() => setAssetTypes(location?.state?.policy?.type)}
          testRegoPolicy={testRegoPolicy}
          disableScopeSelection={disableScopeSelection}
          onPageSizeChange={(pageSize) => setTablePageSize(pageSize)}
          tablePageSize={tablePageSize}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const code = Base64.encode(
          (wizardState?.code || "").replace(
            wizardState?.initialCode || "",
            ""
          ) || ""
        );
        // validate policy code and scope step
        if (_.isEmpty(assetTypes)) {
          setLoadingSubmit(false);
          throw appToast(
            "warning",
            t('exclusion-creation.steps.coding.warning'),
            " "
          );
        }
        if (isEditMode) {
          const updatePayload = {
            name: wizardState?.name,
            description: wizardState?.description,
            code: code,
            type: assetTypes,
            isEnabled: wizardState?.isEnabled,
            unknowns: wizardState?.unknowns || [],
            labels: labels,
          };
          const updated = await dispatch(
            updateExclusion(location?.state?.policy?.id, updatePayload)
          );
          if (!updated?.ok) {
            setLoadingSubmit(false);
            throw new Error(
              t('exclusion-creation.steps.coding.update-error')
            );
          }
        } else {
          const created = await dispatch(
            createExclusion(
              wizardState.name,
              wizardState.description,
              code,
              assetTypes,
              wizardState.unknowns || [],
              labels,
              user.name
            )
          );
          if (!created?.ok) {
            setLoadingSubmit(false);
            throw new Error(t('exclusion-creation.steps.coding.update-error'));
          }
        }
        setLoadingSubmit(false);
      },
    },
    {
      step_title: t('exclusion-creation.steps.completion.title'),
      step_description: "",
      content_title: isEditMode
        ? t('exclusion-creation.steps.completion.content-title_edit')
        : t('exclusion-creation.steps.completion.content-title_new'),
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="ExclutionPolicyCreation">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/excluded-assets")}
        iconSrc={iacIgnoredIcon}
        noPaddingBody
      />
    </div>
  );
};

export default ExclutionPolicyCreation;
