import React, { useState } from "react";
import { Collapse } from "antd";
import { capitalizeFirst } from "../../../../utils/formatting";
import TableWrapper from "../../../../shared/tableWrapper/tableWrapper";

import "./guardrailsBlockTable.scss";
import { getGuardrailsTableColumnsByType } from "./guardrailsTableHelper";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getWorkflowResources } from "../../../../redux/actions/ciWorkflowsActions";
import { GUARDRAILS_TYPES } from "../../../guardrails/guardrailsModal/guardrailsHelper";

const { Panel } = Collapse;

const GuardrailsBlockTable = ({ data, type, Icon, handleOpenConfig, taskId }) => {
    const [selectedPlanResource, setSelectedPlanResource] = useState({});
    const { ruleId, description = "", ruleType = "", ruleName = "", isPassed = false, violations = [] } = data;
    const { t: tColumns } = useTranslation("ci-pipeline", { keyPrefix: "columns"});
    const shouldShowTable = !isPassed && ruleType && ruleId;
    const dataLength = violations?.length;
    
    const dispatch = useDispatch();
    
    const handleOpenPlanConfig = async (address, triggeredTab) => {
        const isElementExists = selectedPlanResource?.address === address;
        if (isElementExists) {
           return handleOpenConfig(selectedPlanResource);
        }
        const filters = { orClauses: { address } };
        const response = await dispatch(getWorkflowResources(taskId, { filters }));
        if (response?.resources?.length > 0) {
            const element = response.resources[0] || {};
            handleOpenConfig(element, triggeredTab);
            setSelectedPlanResource(element);
        }
    }
    const handleRowClick = (params) => {
        if (type === GUARDRAILS_TYPES.cost) return;
        const address = params.row?.assetInfo?.address || "";
        const triggeredTab = type === GUARDRAILS_TYPES.policy ? GUARDRAILS_TYPES.policy : ""
        handleOpenPlanConfig(address, triggeredTab)
    }
    return (
        <Collapse
            className={`GuardrailsBlockTable ${isPassed ? "passed" : ""}`}
            key={ruleId}
            defaultActiveKey={isPassed ? "" : ruleId}
            expandIconPosition="right"
        >
            <Panel key={ruleId} collapsible={isPassed ? "disabled" : null} header={
                <div className="GuardrailsBlockTable__header row g5">
                    {Icon && <Icon className={`GuardrailsBlockTable__item-icon ${isPassed ? "passed" : ""} pointer`}/>}
                    <span className="bold">{capitalizeFirst(type)}</span>
                    <span>|</span>
                    <span className="GuardrailsBlockTable__title unbold">{ruleName}</span>
                </div>
            }>
                 {shouldShowTable &&
                  <TableWrapper
                    autoHeight={!!dataLength}
                    rowKey="id"
                    tableData={violations}
                    columns={getGuardrailsTableColumnsByType(type, tColumns)}
                    disableSelectionOnClick
                    rowsPerPageOptions={[5, 10, 20]}
                    onRowClick={(params) => handleRowClick(params)}
                />}
            </Panel>
        </Collapse>
    );
};

export default GuardrailsBlockTable;