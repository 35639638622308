import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import Popover from "antd/lib/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generalEvents } from "../../../utils/amplitudeEvents";
import { toggleAppTheme } from "../../../redux/actions/userPreferencesActions";
import { sendEvent } from "../../../utils/amplitude";
import DropMenu from "../../../shared/dropMenu/dropMenu";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { isDemoApplication } from "../../../utils/helpers";
import "./userConnected.scss";
import _ from "lodash";
import { FIREFLY_DOCUMENTATION } from "../../../consts/links";

const UserConnected = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const dispatch = useDispatch();
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const isSandboxStatus = useSelector((state) => state.userPreferencesReducer.isSandboxStatus);

  const renderTitle = () => {
    const title = isSandboxStatus ? "Viewer" 
    : !isEmpty(user?.["https://infralight.co/roles"])
    ? user["https://infralight.co/roles"][0] :
    "Admin";
    return (
      <div className="UserConnected__title col">
        <span className="bold">
          {!isEmpty(user?.nickname)
            ? capitalize(user?.nickname)
            : capitalize(user?.name)}
        </span>
        <span className="UserConnected__title-sub">
          {title}
        </span>
      </div>
    );
  };

  const handleLogout = () => {
    const connection = user?.["https://infralight.co/connection"];

    // okta logout
    if (!_.isEmpty(connection) && connection?.includes("okta")) {
      const oktaSubDomain = connection.replace("okta-", "");
      return logout({ returnTo: `https://${oktaSubDomain}.firefly.ai/`, federated: true });
    }
    // azure logout
    if (!_.isEmpty(connection) && connection?.includes("azure")) {
      const azureSubDomain = connection.replace("azure-", "");
      return logout({ returnTo: `https://${azureSubDomain}.firefly.ai/`, federated: true });
    }
    // ping logout
    if (!_.isEmpty(connection) && connection?.includes("ping")) {
      const pingSubDomain = connection.replace("ping-", "");
      return logout({ returnTo: `https://${pingSubDomain}.firefly.ai/`, federated: true });
    }
    // regulat logout
    return logout({ returnTo: window.location.origin });
  };

  const handleDocumentation = () => {
    window.open(FIREFLY_DOCUMENTATION, "_blank");
    sendEvent(generalEvents.pageClick, { page: "Documentation" });
    return;
  };

  const links = [
    {
      text: renderTitle(),
      icon: null,
      onClick: () => {
        return false;
      },
      disabled: false,
      readonly: true,
    },
    {
      text: themeDark ? "Light Mode" : "Dark Mode",
      icon: <FontAwesomeIcon icon={themeDark ? "sun" : "moon"} />,
      onClick: () => dispatch(toggleAppTheme(themeDark)),
      disabled: false,
    },
    {
      text: "Documentation",
      icon: <FontAwesomeIcon icon="book-open" />,
      onClick: handleDocumentation,
      disabled: false,
    },
    {
      text: "Sign Out",
      icon: <FontAwesomeIcon icon="power-off" />,
      onClick: handleLogout,
      disabled: false,
    },
  ];

  if (isAuthenticated) {
    return (
      <Popover
        content={isDemoApplication() ? null : <DropMenu links={links} />}
        title={null}
        placement="bottomRight"
        overlayClassName="UserConnected__wrapper"
      >
        <div className="UserConnected row">
          <div
            className="UserConnected__user"
            style={{ backgroundImage: `url(${user?.picture})` }}
          />
        {!isDemoApplication() &&
          <FontAwesomeIcon
            icon="chevron-down"
            className="UserConnected__arrow"
          />}
        </div>
      </Popover>
    );
  }

  return null;
};

export default UserConnected;
