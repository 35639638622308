import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableWrapperV2 from "../../../shared/tableWrapperV2/tableWrapperV2";
import IacStatusFlag from "../../../shared/iacStatusFlag/iacStatusFlag";
import OwnerIndication from "../ownerIndication/ownerIndication";
import AssetNameCell from "./assetNameCell";
import AssetDateCell from "./assetDateCell";
import AssetPropertiesCell from "./assetPropertiesCell";
import AssetDataSourceCell from "./assetDataSourceCell";
import AssetFlagsCell from "./assetFlagsCell";
import filter from "lodash/filter";
import AssetTypeCell from "./assetTypeCell";
import AssetStacksCell from "./assetStacksCell";
import CustomColumnVisibilityPanel from "../../../shared/tableWrapperV2/components/columnVisibilityPanel/columnVisibilityPanel";
import { useDispatch, useSelector } from "react-redux";
import { updateInventoryColumnVisibilityModel } from "../../../redux/actions/userPreferencesActions";
import { defaultInventoryColumnVisibilityModel } from '../../../consts/inventory'

const inventoryTable = ({
  tableData,
  loading,
  tablePageSize,
  serverSide,
  rowCount,
  handleSelectedRowsArr,
  handlePageChange,
  handleSortChange,
  onPageSizeChange,
  onRowClick,
  disableSelectionOnClick,
  refresh,
  selectedRows,
  handleToggleSelectedView,
  selectedAssetsView,
  isDeleteMode,
  isFreeTier,
  excludedAssets,
  classifications,
  height,
  providerIntegrations,
  handleOpenAnalysis,
  handleOpenMulti,
  handleOpenInsights,
  handleTagClicked,
  handleOpenCommentsTab,
  handleClickOwner,
  handleOpenHistoryTab,
  handleOpenGit,
  handleOpenConnectionTab,
  movePagination,
  isCheckBox,
  getTreeDataPath,
  treeData,
  showRelationFlag,
}) => {
  const { t } = useTranslation("inventory");
  const dispatch = useDispatch();
  const [didMount, setDidMount] = useState(false);

  const inventory_columnVisibilityModel = useSelector(
    (state) => state.userPreferencesReducer.inventory_columnVisibilityModel
  );
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(inventory_columnVisibilityModel)

  useEffect(() => {
    if(didMount){
      dispatch(updateInventoryColumnVisibilityModel(columnVisibilityModel));
    }else{
      setDidMount(true)
    }
  }, [columnVisibilityModel])

  const columns = [
    {
      headerName: t("table.columns.dataSource"),
      field: "integrationId",
      filterable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <AssetDataSourceCell
            row={params?.row}
            providerIntegrations={providerIntegrations}
          />
        );
      },
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.assetType"),
      field: "assetType",
      filterable: false,
      minWidth: 50,
      renderCell: (params) => (
        <AssetTypeCell row={params?.row} />
      ),
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.name"),
      field: "name",
      minWidth: 50,
      filterable: false,
      renderCell: (params) => (
        <AssetNameCell
          value={params.value}
          freeTier={params.row?.isLocked && isFreeTier}
        />
      ),
    },
    {
      headerName: t("table.columns.id"),
      field: "arn",
      minWidth: 50,
      valueGetter: ({ row }) => {
        if (row?.state === 'ghost' || row?.state === 'pending') {
          return row?.frn;
        }
        return row?.arn;
      },
      filterable: false,
    },
    {
      headerName: t("table.columns.state"),
      field: "state",
      filterable: false,
      minWidth: 50,
      renderCell: (params) => (
        <IacStatusFlag
          state={params.row?.state}
          iacType={params.row?.iacType}
          hasControllerSources={params.row?.hasControllerSources}
          handleOpenAnalysis={handleOpenAnalysis}
          rowData={params.row}
          handleOpenMulti={handleOpenMulti}
          isLocked={params.row?.isLocked && isFreeTier}
        />
      ),
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.stacks"),
      field: "stacks",
      renderCell: (params) => (
        <AssetStacksCell
          data={params?.row}
        />
      ),
      filterable: false,
      sortable: false,
      minWidth: 50,
    },
    {
      headerName: t("table.columns.location"),
      field: "region",
      filterable: false,
      minWidth: 50,
    },
    {
      headerName: t("table.columns.properties"),
      field: "assetId",
      sortable: false,
      filterable: false,
      minWidth: 50,
      renderCell: (params) => (
        <AssetPropertiesCell
          value={params.value}
          row={params?.row}
          onClickTag={handleTagClicked}
          isFreeTier={params.row?.isLocked && isFreeTier}
        />
      ),
    },
    {
      headerName: t("table.columns.owner"),
      field: "ownerData",
      type: "boolean",
      filterable: false,
      sortable: false,
      minWidth: 50,
      disableColumnMenu: true,
      renderCell: (params) => (
        <OwnerIndication
          data={params?.value}
          handleClickOwner={handleClickOwner}
        />
      ),
    },
    {
      headerName: t("table.columns.updated"),
      field: "lastConfigurationChangeTime",
      filterable: false,
      minWidth: 50,
      renderCell: (params) => (
        <AssetDateCell
          value={params?.value}
          isFreeTier={isFreeTier}
          isDeleteMode={true}
        />
      ),
    },
    {
      headerName: isDeleteMode
        ? t("table.columns.deleted")
        : t("table.columns.created"),
      field: isDeleteMode ? "deletedAt" : "resourceCreationDate",
      filterable: false,
      minWidth: 50,
      renderCell: (params) => (
        <AssetDateCell
          value={params?.value}
          isFreeTier={isFreeTier}
          isDeleteMode={isDeleteMode}
        />
      ),
    },
    {
      headerName: t("table.columns.flags"),
      field: "resourceStatus",
      minWidth: 50,
      width: 230,
      filterable: false,
      sortable: false,
      resizable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <AssetFlagsCell
          value={params.value}
          row={params?.row}
          isFreeTier={params.row?.isLocked && isFreeTier}
          classifications={classifications}
          handleOpenInsights={(e) => {
            e.stopPropagation();
            handleOpenInsights(params?.row);
          }}
          handleOpenGit={(e) => {
            e.stopPropagation();
            handleOpenGit(params?.row);
          }}
          handleOpenHistoryTab={(e) => {
            e.stopPropagation();
            handleOpenHistoryTab(params?.row);
          }}
          handleOpenCommentsTab={(e) => {
            e.stopPropagation();
            handleOpenCommentsTab(params?.row);
          }}
          handleOpenConnectionTab={(e) => {
            e.stopPropagation();
            handleOpenConnectionTab(params?.row);
          }}
          excludedAssets={excludedAssets}
          showRelationFlag={showRelationFlag}
        />
      ),
    },
  ];

  const tableColumns = treeData ? filter(columns, (i) => !i?.hideFromTree) : columns;

  return (
    <TableWrapperV2
      rowKey="id"
      tableData={tableData}
      height={height}
      loading={loading}
      isCheckBox={isCheckBox}
      pageSize={tablePageSize}
      serverSide={serverSide}
      movePagination={movePagination}
      rowCount={rowCount}
      handleSelectedRowsArr={handleSelectedRowsArr}
      handlePageChange={handlePageChange}
      handleSortChange={handleSortChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={onRowClick}
      disableSelectionOnClick={disableSelectionOnClick}
      refresh={refresh}
      keepSelectedRows
      selectedRows={selectedRows}
      handleToggleSelectedView={handleToggleSelectedView}
      selectedAssetsView={selectedAssetsView}
      getTreeDataPath={getTreeDataPath}
      treeData={treeData}
      columns={tableColumns}
      treeColumnTitle={t("table.columns.assetType")}
      ColumnMenu={(menuProps)=>  {
        return (
          <CustomColumnVisibilityPanel
            defaultColumnVisibilityModel={defaultInventoryColumnVisibilityModel}
            menuProps={menuProps}
            tableColumns={tableColumns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityChange={updatedValues => setColumnVisibilityModel(updatedValues)}
          />
        )
      }}
      onColumnVisibilityModelChange={values => setColumnVisibilityModel({...columnVisibilityModel, ...values})}
      columnVisibilityModel={columnVisibilityModel}
    />
  );
};

export default inventoryTable;
