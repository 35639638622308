import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { nicknameRegex, validate } from "../../../utils/validations";

const MongodbAtlasForm = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, publicKey, privateKey } = formValues;

    if (_.isEmpty(name) || _.isEmpty(publicKey) || _.isEmpty(privateKey)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };


  return (
    <div className="MongodbAtlasForm col">
      <div className="col">
        <span className="dark-blue bold">
          Procedure
        </span>
        <ol className="MongodbAtlasForm__intro-orderedlist">
          <li>
            <a target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }} href="https://cloud.mongodb.com">Log in to your MongoDB Atlas account</a>.
          </li>
          <li> 
            From the left menu, select <span className="bold">Access Manager</span>.
          </li>
          <li> 
            Select the Organization. 
          </li>
          <li> 
            From the top, select <span className="bold">API Keys &gt; Create API Key</span>.
          </li>
          <li> 
            Enter a <span className="bold">Description</span>.
          </li>
          <li> 
            In <span className="bold"> Organization Permissions </span>, select <span className="bold"> Organization Read Only &gt; Next</span>.
          </li>
          <li> 
            Copy the <span className="bold">Public Key</span> and <span className="bold">Private Key</span>.
          </li>
          <li> 
            In Firefly, enter a descriptive name in the <span className="bold">Nickname</span> field.
          </li>
          <li> 
            Paste the <span className="bold">Public Key</span> and <span className="bold">Private Key</span>.
          </li>
          <li> 
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>

      <Form
        {...layout}
        name="mongodbatlas-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "MongoDB Atlas Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Public Key"
          name="publicKey"
          rules={[
            {
              required: true,
              message: "Public Key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Private Key"
          name="privateKey"
          rules={[
            {
              required: true,
              message: "Private Key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default MongodbAtlasForm;
