import { filter, flatten, isEmpty, keys, map } from "lodash";

export const getProdIntegratioNumbers = (providerIntegrations) => {
    if (!isEmpty(providerIntegrations)) {
      let allProviders = map(keys(providerIntegrations), (providerKey) => {
        return map(providerIntegrations?.[providerKey] || [], (integ) => {
          return {
            ...integ,
            provider: providerKey,
          };
        });
      });
      allProviders = flatten(allProviders);

      const prodIntegration = filter(allProviders, (integ) => integ?.isProd === true);
      const prodIntegratioNumbers = map(prodIntegration || [], (item) => {
        return item?.id;
      });

      return prodIntegratioNumbers;
    }
    return [];
  };