import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { updateExclusion } from "../../../redux/actions/exclusionsActions";
import { updateExclusion as updateDriftExclusion } from "../../../redux/actions/driftsExclusionsActions";
import AppToggle from "../../../shared/appToggle/appToggle";
import { appToast } from "../../../shared/appToast/appToast";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { dateTimeFormat, formatGlobalScopeTitle, getAllProvidersArray, numberWithCommas } from "../../../utils/formatting";

import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TimeIcon from "../../../Images/general_icons/Time_icon.svg";
import { ReactComponent as UserIcon } from "../../../Images/general_icons/user_icon.svg";
import { Popover } from "antd";

import "./singleExcluded.scss";
import { getIntegrationsId } from "../../../utils/helpers";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";

const SingleExcluded = ({
  id,
  name,
  description,
  isEnabled,
  isDefault,
  assetType,
  properties,
  onClickedCode,
  onClickedEdit,
  onClickedDelete,
  itemLabels,
  itemDataSources,
  type,
  createdAt,
  // integrations,
  username,
  providers,
  assetTypesArr,
  rego,
  isExcludedAssets,
  count = 0,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('excluded-drifts')
  const [checked, setChecked] = useState(isEnabled);
  const [loading, setLoading] = useState(false);

  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const providerIntegrations = useSelector((state) => state.globalAppReducer.clouds);
 
  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );

  const integrations = useSelector(
    (state) => state.insightsV2Reducer.filters?.integrations
  );

  const allProviders = useMemo(() => getAllProvidersArray(providerIntegrations), [providerIntegrations]);
  
  const isAssetType = type === "asset";
  const isDriftType = type === "drift";

  const handleToggleChange = async () => {
    setLoading(true);
    // Not changeable by this toggle
    let code, unknowns, labels;
    let exclusionRes, driftExclusionRes;

    if (isAssetType) {
      let payload = {
        name,
        description,
        isEnabled: !checked,
        code: rego,
        type: assetTypesArr,
        unknowns,
        labels,
      };
      exclusionRes = await dispatch(updateExclusion(id, payload));
      if (!exclusionRes?.ok) {
        return setLoading(false);
      }
    } else { 
      let payload = {
        name,
        description,
        code,
        type: assetType,
        labels,
        isEnabled: !checked,
        integrations: getIntegrationsId(providers, allProviders)
      };
      driftExclusionRes = await dispatch(updateDriftExclusion(id, payload));
      if (!driftExclusionRes?.ok) {
        return setLoading(false);
      }
    }

    setLoading(false);
    if (!checked) {
      appToast(
        "info",
        t('single-excluded.toast.title', { context: type }),
        t('single-excluded.toast.description', { context: type }),

      );
    }
    setChecked(!checked);
  };

  const renderDriftExclusionName = (type) => {
    const name = formatGlobalScopeTitle(type, true);
    return (
      <span className="SingleExcluded__text-name row">
        <span className="SingleExcluded__text-name title">{t('single-excluded.scope')} </span>
        {name}
      </span>
    );
  };

  const formatProperties = () => {
    if (!_.isEmpty(properties)) {
      return (
        <span className="SingleExcluded__properties row">
          <span className="bold">{t('single-excluded.properties')} </span>
          {_.map(properties, (property, index) => (
            <span
              key={property.toString() + index.toString()}
              className="SingleExcluded__properties-property"
            >
              {property}
            </span>
          ))}
        </span>
      );
    }
  };

  const formatIntegrations = () => {
    const allProviders = getAllProvidersArray(providerIntegrations);
    return (
      <div className="SingleExcluded__text-description-providers row">
        {_.map(providers || [], (accountNumber) => {
          const match = allProviders.find((item) => item?.accountNumber === accountNumber);
          if (!_.isEmpty(match)) {
            return (
              <span
                className="SingleExcluded__text-description-providers-single row"
                key={match?.id}
              >
                <ProviderIcon provider={match?.provider} customStyle="excluded" />
                {match?.name}
              </span>
            );
          }
        })}
      </div>
    );
  };
  const getDataSourcePopoverContent = () => {
    return (
      <ul className="SingleExcluded__dataSources-list-popover">
        {assetTypesArr?.map(t => (
          <li key={t}>
            <span className="row">
              <ProviderIcon provider={_.split(t, '_')[0]} customStyle="notification" />
              {t}
            </span>
          </li>
        ))}
      </ul>)
  };
  const isDriftProviders = !_.isEmpty(providers) && isDriftType;

  const handleViewAssets = (e) => {
    if(!(count > 0)) return 
    e.stopPropagation();

    dispatch(
      setFilters({
        exclusions: [id],
        excluded: showExclusion,
        providerTypes: {
          integrationId: integrations,
        },
        masterType: []
      })
    );

     return history.push({
      pathname: "/inventory" ,
      search: "?defaultView=false",
    });
  };

  return (
    <div className="col">
          <div className={`SingleExcluded ${isDriftProviders ? "noPaddingBottom" : ""} row between`}>
              <div className="SingleExcluded__text col">
                <span className="SingleExcluded__text-name row bold">
                  {isAssetType ? name : renderDriftExclusionName(assetType)}
                  {isExcludedAssets && isEnabled && 
                  <span
                  className={`row g5 font-13 ${count > 0 ? `purple-flag pointer` : `grey-flag`}`}
                  onClick={handleViewAssets}
                >
                  <FontAwesomeIcon icon="layer-group" className={`${count > 0 ? `purple-text` : `disabledText`}`} />
                  {numberWithCommas(count)}
                  {count > 0 && <FontAwesomeIcon
                    icon="external-link-alt"
                    className="mgLeft5 font-11"
                  />}
                </span>}
                </span>
                {isDriftType && 
                <div className="SingleExcluded__text-description drift col">
                  {formatProperties()}
                </div>}
                {username ? (
                  <>
                    {isAssetType && description}
                    <div className="SingleExcluded__user-information">
                      <div>
                        <img src={TimeIcon} alt="clock icon" width={12} />
                        <span>{moment(createdAt).format(dateTimeFormat)}</span>
                      </div>
                      <>
                        <span className="SingleExcluded__divider"></span>
                        <div className="row g5">
                          <UserIcon style={{width: '12px'}}/>
                          <span>{username}</span>
                        </div>
                      </>
                    </div>
                  </>
                ) : (
                  <>
                    {(isDefault || isAssetType) && (
                      <div
                        className={`SingleExcluded__text-description ${isAssetType ? "" : "drift"
                          }`}
                      >
                        {isDefault && (
                          <span className="SingleExcluded__text-description-created bold">
                            {t('single-excluded.created-by')} <span>Firefly</span>
                          </span>
                        )}
                        {isAssetType && description}
                      </div>
                    )}
                  </>
                )}
                <div className="row">
                  <div className="SingleExcluded__dataSources row">
                    <div className="SingleExcluded__dataSources-list row">
                      {!_.isEmpty(itemDataSources) &&
                        _.map(itemDataSources, (item) => (
                          <Popover key={item}  placement="bottomLeft" content={getDataSourcePopoverContent()}>
                            <span
                            className="SingleExcluded__dataSources-list-item row"
                            key={item}>
                            <ProviderIcon provider={item} customStyle="notification" />
                            {item}
                            </span>
                          </Popover>
                        ))}
                    </div>
                  </div>
                  {!_.isEmpty(itemLabels) && <div className="SingleExcluded__labels row">
                    <span>Labels:</span>
                    <div className="SingleExcluded__labels-list row">
                      {!_.isEmpty(itemLabels) &&
                        _.map(itemLabels, (item) => (
                          <span className="purple-flag" key={uuidv4()}>
                            {item}
                          </span>
                        ))}
                    </div>
                  </div>}
                </div>
              </div>
              <div className="SingleExcluded__actions row">
                {isAssetType && (
                  <div
                    onClick={onClickedCode}
                    className="SingleExcluded__actions-action code-btn row"
                  >
                    <FontAwesomeIcon icon={faCode} />
                    {t('single-excluded.view-code')}

                  </div>
                )}
                {!isDefault && isAssetType && (
                  <div className="SingleExcluded__actions-action row">
                    <IconBtn
                      type="edit"
                      onClick={onClickedEdit}
                      disabled={isViewer}
                      colorClass="purple"
                    />
                  </div>
                )}
                {!isDefault && (
                  <div className="SingleExcluded__actions-action row delete-btn">
                    <IconBtn
                      type="delete"
                      onClick={() => onClickedDelete(checked)}
                      disabled={isViewer}
                      colorClass="red"
                    />
                  </div>
                )}
                <div className="row SingleExcluded__actions-toggle-btn">
                  <AppToggle
                    checked={checked}
                    toggleChecked={handleToggleChange}
                    loading={loading}
                    isDefault={isDefault}
                    disabled={isViewer}
                  />
                </div>
              </div>
            </div>
        {isDriftProviders && (
          <div className="SingleExcluded__text-description withOverflow col">
            {!_.isEmpty(providers) && formatIntegrations()}
          </div>
        )}
    </div>

  );
};

export default SingleExcluded;
