import React, { useEffect, useMemo, useState } from "react";

import { TIME_FILTERS, getTimestampByTimeFilter } from "../eventCenterHelper";
import AppPopoverSelect from "../../../shared/appPopoverSelect/appPopoverSelect";
import { Divider } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { getEventCenterAggs, setEventCenterFilters } from "../../../redux/actions/eventCenterActions";
import { clouds } from "../../../utils/icons";
import "./EventCenterFilters.scss";

const EventCenterFilters = ({ tEvents, allIntegs = {} }) => {
    const [loading, setLoading] = useState(false);
    const filters = useSelector((state) => state.eventCenterReducer.filters);
    const aggs = useSelector((state) => state.eventCenterReducer.aggs) || {};
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    const dispatch = useDispatch();
    
    const selectedTime = filters?.selectedTime;
    const locationAggs = useMemo(() => Object.entries(aggs?.locations || {}).map(([location]) => ({ label: location, value: location })), [aggs?.locations]);

    useEffect(() => {
        getEventAggs();
    }, []);

    const onChangeTimeFilter = (timeFilter) => {
        setFilters({ selectedTime: timeFilter });
        getEventAggs(timeFilter);
    }

    const getEventAggs = async (timeFilter) => {
        setLoading(true);
        const timestamp = getTimestampByTimeFilter(timeFilter || selectedTime);
        const newFilters = { from: timestamp, to: new Date().getTime() };
        delete newFilters.selectedTime;
        await dispatch(getEventCenterAggs({ filters: newFilters, skip: 0, limit: 10 }));
        setLoading(false);
    }

    const setFilters = async(newFilter = {}) => await dispatch(setEventCenterFilters({ ...filters, ...newFilter }));
    const onClear = async() => await dispatch(setEventCenterFilters({ selectedTime: TIME_FILTERS[1] }));

    return (
        <div className="EventCenterFilters row g10">
            <span>{tEvents("filterBy")}</span>
            <div className="EventCenterFilters__filters row">
                {TIME_FILTERS.map((timeFilter) => (
                <span className={`EventCenterFilters__filters-item ${timeFilter} ${timeFilter === selectedTime ? "selected" : ""}`} key={timeFilter} onClick={(e) => onChangeTimeFilter(timeFilter)}>
                    {tEvents(`filters.time-ranges.${timeFilter}`)}
                </span>))}
            </div>
            
            <AppPopoverSelect
                   loading={loading}
                   label={tEvents("filters.data-source")}
                   options={Object.entries(aggs?.integrations || {}).map(([integId]) => {
                    const { name: integrationName = "", provider } = allIntegs[integId] || {};
                    return ({
                            label: <span className="row g5" key={integId}>
                                        {<img className="EventCenterFilters__filters-item-icon" src={clouds(provider, themeDark)} />}
                                        {integrationName}
                                    </span>,
                            value: integId
                    })
                    })}
                    checkedOptions={filters?.integrationIds || []}
                    onSubmit={(selected) => setFilters({ integrationIds: selected })}/>
                
                <AppPopoverSelect
                    loading={loading}
                    label={tEvents("filters.location")}
                    options={locationAggs}
                    checkedOptions={filters?.locations || []}
                    onSubmit={(selected) => setFilters({ locations: selected })}/>

                <Divider type="vertical" className="EventCenterFilters-divider"/>
                <span className="purple-text underline pointer" onClick={onClear}>{tEvents("clearAll")}</span>
        </div>
    )
};

export default EventCenterFilters;