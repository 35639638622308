import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import "./vaultForm.scss";
import { getValidationRules } from "../../../utils/integrations";

const VaultEditForm = ({ url }) => {

  return (
    <>
        <Form.Item
            label={
              <>
                Vault Endpoint
                <br />
                <span className="vault-endpoint-example">Example: https://example.url.com or http://12.123.123.1:1234</span>
              </>
            }
          name="url"
          rules={getValidationRules(url)}
          className="vault-item-column"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
          ]}
          className="vault-item-column"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
          className="vault-item-column"
        >
          <Input.Password />
        </Form.Item>
    </>
  );
};

export default VaultEditForm;
