import { GET_EVENTS_DATA, GET_EVENT_CENTER_AGGS, SET_EVENT_CENTER_FILTERS } from "../actions/eventCenterActions";

const initialState = {
    events: [],
    filters: {
        selectedTime: "3d",
    },
    totalEvents: 0,
    aggs: {},
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_EVENTS_DATA:
            return { ...state, events: payload?.data || [], totalEvents: payload.revisions_count || 0 };
        case GET_EVENT_CENTER_AGGS:
            return { ...state, aggs: payload };
        case SET_EVENT_CENTER_FILTERS: 
            return { ...state, filters: payload };
        default:
            return state;
    }
}