/* eslint-disable */
export const emailRegex = {
  regex: new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"),
    msg: 'a valid email looks like this: exmaple@example.com'
};

export const nameRegex = {
  regex: new RegExp("^[a-zA-Z0-9][a-zA-Z0-9_:.\/@+,-]*$"),
  msg: 'Names must start with alphanumeric characters and can contain any of the following: _+.@,-"',
};

export const nameWithSpaceRegex = {
  regex: new RegExp("^[a-zA-Z0-9][a-zA-Z0-9 _:.\/@+,-]*$"),
  msg: 'Names must start with alphanumeric characters and can contain any of the following: _+.@,-"',
};

export const nicknameRegex = {
  regex: new RegExp("^[a-zA-Z0-9][a-zA-Z0-9 _:.\/-]*$"),
  msg: 'Nicknames must start with alphanumeric characters, can contain any of the following: _.-" and up to 25 characters long',
};

export const descriptionRegex = {
  regex: new RegExp("^[a-zA-Z0-9 _.\/+,:;-]*$"),
  msg: "Descriptions must start with alphanumeric characters and can contain any of the following: _.+,:;-",
};
export const descriptionRegexMultiline = {
  regex: new RegExp("^[a-zA-Z0-9 _.\/+,:;-]*$", "m"),
  msg: "Descriptions must start with alphanumeric characters and can contain any of the following: _.+,:;-",
};

export const charNumHyfenOnly = {
  regex: new RegExp("^[a-z0-9-_]+$"),
  msg: "Only small characters and numbers are allowed without spaces('-' or '_')",
};

export const accountNumberRegex = {
  regex: new RegExp("^[0-9]{12}$"),
  msg: "Only 12 digit numbers are allowed",
};

export const numberRegex = {
  regex: new RegExp("^[0-9]*$"),
  msg: "Only numbers are allowed",
};

export const guidRegex = {
  regex: /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
  msg: "must be a valid GUID",
};
export const folderRegex = {
  regex: new RegExp("^\/(?:[^\/\.]+\/)*[^\/\.]+\/?$"),
  msg: "must be a valid folder path",
}
export const validate = (regex, value) => {
  const regexTest = new RegExp(regex);
  return regexTest.test(value);
};

export const isValidObjectId = (str) => /^[0-9a-fA-F]{24}$/.test(str);

export const vaultCheckValidation = (formValues = {}, form = {}) => {
  const { url = '', username = "", password = "" } = formValues;

  if (_.isEmpty(url) || _.isEmpty(username) || _.isEmpty(password)) {
    return false;
  }

  if(form.getFieldError('url').length > 0 || form.getFieldError('username').length > 0 || form.getFieldError('password').length > 0) {
    return false;
  }

  return true;
};

export const vaultUrlValidation = (url = '') => {
  const combinedRegex = /^(https?:\/\/)((?!-)(?:[a-zA-Z\d-]+\.)+[a-zA-Z]{2,}(?::\d{2,5})?(?:[\/\w .-]*)*\/?|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):\d+)$/;
  const protocolRegex = /^(https?:\/\/)/;

  if (!protocolRegex.test(url)) {
    return false;
  }

  return combinedRegex.test(url);
}

