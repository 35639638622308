import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getInsightsByAccount,
  getInsightsByAggs,
  setInsightsFilters,
} from "../../redux/actions/insightsV2Actions";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import { getJiraIntegrations } from "../../redux/actions/jiraIntegrationActions";
import InsightsFilters from "../../components/Insights/insightsFilters/insightsFilters";
import InsightsHeader from "../../components/Insights/insightsHeader/insightsHeader";
import InsightsTable from "../../components/Insights/insightsTable/insightsTable";
import "./insights.scss";
import InsihtsFrameworksScore from "../../components/Insights/insightsFrameworksScore/insihtsFrameworksScore";
import { ACOOUNT_PROVIDERS } from "../../consts/general";
import isEmpty from "lodash/isEmpty";
import { addProviderNames } from "../../utils/insightsHelper";

const Insights = () => {
  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(true);
  const insightsReducer = useSelector((state) => state.insightsV2Reducer);
  const globalAppReducer = useSelector((state) => state.globalAppReducer);

  useEffect(() => {
    fetchInit();
  }, []);

  useEffect(() => {
    if (!isEmpty(globalAppReducer?.clouds)) {
      fetchTableData();
    }
  }, [
    globalAppReducer.clouds,
    insightsReducer.filters,
    insightsReducer.tableFilters,
  ]);

  useEffect(() => {
    fetchFiltersData();
  }, [insightsReducer.filters]);

  const fetchTableData = async () => {
    setTableLoading(true);

    const payload = {
      ...insightsReducer.filters,
      ...insightsReducer.tableFilters,
    };

    const cloudDataKeys = Object.keys(globalAppReducer?.clouds).filter(
      (key) => globalAppReducer?.clouds[key]?.length > 0
    );
    const supportedProviders = cloudDataKeys
      .filter((key) => key in ACOOUNT_PROVIDERS)
      .map((key) => ACOOUNT_PROVIDERS[key]);

    const allProviders = addProviderNames(supportedProviders);

    await dispatch(getInsightsByAccount(payload, allProviders));

    setTableLoading(false);
  };

  const fetchFiltersData = async () => {
    await dispatch(getInsightsByAggs(insightsReducer.filters));
  };

  const fetchInit = async () => {
    const cloudFields = "id,name,isProd,accountNumber";

    const payload = {
      ...insightsReducer.filters,
      onlyAvailableProviders: true,
    };

    await Promise.all([
      dispatch(getJiraIntegrations()),
      dispatch(getAllClouds(false, cloudFields)),
      dispatch(setInsightsFilters(payload)),
    ]);
  };

  return (
    <div
      className={`InsightsPage ${
        insightsReducer.filtersOpen ? "" : "collapsed"
      }`}
    >
      <InsightsFilters />
      <div className="InsightsPage__content col g20">
        <span className="InsightsPage__content__grid">
          <InsightsHeader className="InsightsPage__content__insightsHeader" />
          <InsihtsFrameworksScore
            className="InsightsPage__content__insightsFrameworksScore"
            loading={tableLoading}
          />
        </span>
        <InsightsTable loading={tableLoading} />
      </div>
    </div>
  );
};

export default Insights;
