import React, { useEffect, useState } from "react";
import TypingDots from "../typingDots/typingDots";

  const LongLoader = ({
    customLoader = null,
    loading = false,
    msg1 = '',
    msg2 = 'Hold on, it is almost ready',
    duration = 30000,
    customClassName = "",
    withTypingDots = false
}) => {
    const [userWaitingLong, setUserWaitingLong] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
              setUserWaitingLong(true);
            }
        }, duration);
        return () => clearTimeout(timer);
    }, [loading]);

    if (withTypingDots) {
        return (
        <div className="LongLoader col g10 center font-18 bold">
            {customLoader}
            <span className="row g8">
                {userWaitingLong ? msg2 : msg1}
                <TypingDots isInText/>
            </span> 
        </div>)
    }
    return (
        <div className={`LongLoader ${customClassName}`}>
            {customLoader}
            <span>
                {userWaitingLong ? msg2 : msg1}
            </span>
        </div>
    )
}
export default LongLoader;