import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import LinkSubMenu from "./linkSubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as MatchingRules } from "../../../Images/general_icons/classification.svg";
import { ReactComponent as DashboardIcon } from "../../../Images/general_icons/sidebar/dashboard.svg";
import { ReactComponent as InventoryIcon } from "../../../Images/general_icons/sidebar/inventory.svg";
import { ReactComponent as EventCenterIcon } from "../../../Images/general_icons/sidebar/event-explorer.svg";
import { ReactComponent as AnalyticsIcon } from "../../../Images/general_icons/sidebar/analytics.svg";
import { ReactComponent as NotificationIcon } from "../../../Images/general_icons/sidebar/notification.svg";
import { ReactComponent as CiPipelineIcon } from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { ReactComponent as BackupIcon } from "../../../Images/general_icons/sidebar/backup.svg";
import { ReactComponent as Yak } from "../../../Images/general_icons/yak.svg";
import { generalEvents } from '../../../utils/amplitudeEvents';
import { sendEvent } from '../../../utils/amplitude';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import "./navLinks.scss";

const NavLinks = () => {
  const { t } = useTranslation("sidebar");
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );
  const isAnalyticsEnabled = useSelector((state) => state.onbordingReducer.account.enabledRetoolApps?.analytics);
  const activeFeatures = useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const isBackupEnabled = activeFeatures.find((feature = {}) => feature.path === '/backup-dr');

  const navLinks = [
    {
      text: t("dashboard"),
      url: "/dashboard",
      icon: <DashboardIcon />,
      exact: false,
      permission: true,
    },
    {
      text: t("inventory"),
      url: "/inventory",
      icon: <InventoryIcon />,
      exact: false,
      permission: true,
    },
    {
      text: t("iac-explorer"),
      url: "/iac",
      icon: <Yak />,
      exact: false,
      permission: true,
    },
    {
      text: t("workflows"),
      icon: <CiPipelineIcon />,
      exact: false,
      permission: true,
      children: [
        {
          text: t("workspaces"),
          url: "/workflows",
          exact: true,
        },
        {
          text: t("guardrails"),
          url: "/workflows/guardrails",
          exact: true,
        }
      ],
    },
    {
      text: t("insights"),
      url: "/governance",
      icon: <MatchingRules />,
      exact: false,
      permission: true,
    },
    {
      text: t("settings.backup"),
      url: "/backup",
      icon: <BackupIcon />,
      exact: false,
      permission: isBackupEnabled,
    },
    // {
    //   text: t("event-center"),
    //   url: "/event-center",
    //   icon: <EventCenterIcon />,
    //   exact: false,
    //   permission: true,
    // },
    {
      text: t("settings.analytics"),
      url: "/analytics",
      icon: <AnalyticsIcon />,
      exact: false,
      permission: isAnalyticsEnabled,
    },
    {
      text: t("settings.notifications"),
      url: "/notifications",
      icon: <NotificationIcon />,
      exact: false,
      permission: true,
    },
    {
      text: t("settings.title"),
      icon: <FontAwesomeIcon icon="cog" />,
      exact: false,
      permission: true,
      children: [
        {
          text: t("settings.excluded-assets"),
          url: "/iac-ignored-rules",
          exact: false,
        },
        {
          text: t("settings.excluded-drifts"),
          url: "/excluded-drifts",
          exact: false,
        },
        {
          text: t("settings.integrations"),
          url: "/integrations",
          exact: false,
        },
        {
          text: t("settings.users"),
          url: "/users",
          exact: false,
        },
      ],
    },
  ];

  const renderNavLinks = (arr) => {
    return arr.map((item) => {
      if (item?.permission) {
        if (item?.children) {
          return (
            <LinkSubMenu
              icon={item?.icon}
              text={item?.text}
              key={item.url + item.text}
              sidebarClosed={sidebarClosed}
              children={item?.children}
            />
          );
        }
        return (
          <NavLink
            to={item.url}
            key={item.url + item.text}
            className={`NavLinks__item ${sidebarClosed && "centered"}`}
            activeClassName="activeNavLink"
            exact={item.exact}
            isActive={item.isActive}
            onClick={() => sendEvent(generalEvents.pageClick, { page: item?.text })}
          >
            <Tooltip title={sidebarClosed ? item.text : ''} placement="right">
              <span className="NavLinks__item-icon">{item.icon}</span>
            </Tooltip>
            {!sidebarClosed && (
              <div className="NavLinks__withNew row">
                <span className="NavLinks__item-text">{item.text}</span>
                {item?.new && <span className="NavLinks__item-new">BETA</span>}
              </div>
            )}
          </NavLink>
        );
      }
    });
  };

  return <div className="NavLinks">{renderNavLinks(navLinks)}</div>;
};

export default NavLinks;
