import React, { useState, useEffect } from "react";
import { Drawer, Tabs, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  fetchGeneratedIaC,
  setPullRequestParameters,
  downloadZipModuleFiles,
  fetchGeneratedCodify,
  fetchGeneratedCloudMigration,
} from "../../../redux/actions/iacImportActions";
import { setTerraformCmds, setTerraformCmdsRedacted } from "../../../redux/actions/inventoryv3Actions";
import { providers } from "../../../utils/providers";
import { terraformK8sResources } from "../../../utils/resources";
import { CODIFY_TYPES, PROVIDERS, RESOURCE_STATE_TYPES } from "../../../consts/general";
import { formatCodifyTabName } from "../../../utils/formatting";

import AppBtn from "../../../shared/appBtn/appBtn";
import ActionBtn from "../../../shared/actionBtn/actionBtn";
import TerraformCodification from "../terraformCodification/terraformCodification";
import LanguageSwitcher from "../../../shared/languageSwitcher/languageSwitcher";
import SmartCodifyTab from "../smartCodifyTab/smartCodifyTab";
import { codifyIcons } from "../../../utils/icons";
import { ReactComponent as ImportCmdsIcon } from "../../../Images/codify/import_cmds.svg";

import AppToggle from "../../../shared/appToggle/appToggle";
import {
  ACTIVE_CODIFICATION_TYPES,
  CODIFY_LANGUAGES,
  LANGS_SUFFIX,
  CODIFICATION_MODES,
  alertIfCodifyRedact,
  handleCallbackByActiveTab,
  onCodifyDrawerVisible,
  MIGRATION_PROVIDERS_TYPES,
  CREATE_MODULE_CODIFICATION_MODES,
  getModuleCallPreparedContent,
  MODULE_CALL_TABS,
} from "./codifyHelpers";
import "./codifyDrawer.scss";


const CodifyDrawer = ({
  visible,
  closeDrawer,
  handleOpenPullRequest,
  selectedResources = [],
  handleOpenTerraformImport,
}) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const moduleData = useSelector((state) => state.iacImportReducer.moduleData);
  const selectedModuleCallCode = useSelector((state) => state.iacImportReducer.selectedModuleCallCode);
  const pullReqParams = useSelector((state) => state.iacImportReducer.pullReqParams);
  const moduleFileSelected = useSelector((state) => state.iacImportReducer.moduleFileSelected);
  const moduleDataCreated = useSelector((state) => state.iacImportReducer.moduleDataCreated);
  const createModulesRedactedFiles = useSelector((state) => state.iacImportReducer.redactedValues) || {};
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const [isFetchingIaC, setIsFetchingIaC] = useState(false);
  const [isLoadingOtherIac, setIsLoadingOtherIac] = useState(false);
  const [terraformIacData, setTerraformIacData] = useState(null);
  const [tfCdkData, setTfCdkData] = useState(null);
  const [k8sIacData, setK8sIacData] = useState(null);
  const [otherIacData, setOtherIacData] = useState({});
  const [activeTab, setActiveTab] = useState("terraform");
  const [activeLanguage, setActiveLanguage] = useState("typescript");
  const [activeCodificationType, setActiveCodificationType] = useState("naive");
  const [loadingZipModuleFiles, setLoadingZipModuleFiles] = useState(false);
  const [isImportBlocksClicked, setIsImportBlocksClicked] = useState(false);
  const [isProviderBlockClicked, setIsProviderBlockClicked] = useState(false);
  const [importBlocks, setImportBlocks] = useState([]);
  const [providerBlock, setProviderBlock] = useState("");
  const [isOverweightResponse, setIsOverweightResponse] = useState(false);
  const [isK8sRedacted, setIsK8sRedacted] = useState(false);

  const revisionIdFromCofifyButton = selectedResources?.length > 0 && selectedResources[0].revisionId ? selectedResources[0].revisionId : undefined;
  
  const mapData = {
    terraform: terraformIacData,
    tfcdk: tfCdkData,
    pulumi: otherIacData[CODIFY_TYPES.pulumi],
    k8s: k8sIacData,
    cloudformation: otherIacData[CODIFY_TYPES.cloudformation],
    cdk: otherIacData[CODIFY_TYPES.cdk],
    cdkl2: otherIacData[CODIFY_TYPES.cdkl2],
    crossplane: otherIacData[CODIFY_TYPES.crossplane],
    ansible: otherIacData[CODIFY_TYPES.ansible],
    configConnector: otherIacData[CODIFY_TYPES.configConnector],
    googleCloudSdk: otherIacData[CODIFY_TYPES.googleCloudSdk],
    helm: otherIacData[CODIFY_TYPES.helm],
    cdk8s: otherIacData[CODIFY_TYPES.cdk8s],
    arm: otherIacData[CODIFY_TYPES.arm],
    bicep: otherIacData[CODIFY_TYPES.bicep],
  };

  const isTerraformOrTfCdk = activeTab === CODIFY_TYPES.terraform || activeTab === CODIFY_TYPES.tfcdk;
  const isK8sSelectedProvider = selectedResources[0]?.provider === "k8s";
  const isSelectedResourceDeleted = selectedResources[0]?.state === RESOURCE_STATE_TYPES.deleted
  const isModulesAndYamlTab = activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules && selectedModuleCallCode === MODULE_CALL_TABS.yaml

  useEffect(() => {
    if (visible) {
      onCodifyDrawerVisible(selectedResources, setActiveCodificationType, setActiveTab, fetchIacImport, fetchCloudMigration);
    }
  }, [visible]);

  useEffect(() => {
    setIsImportBlocksClicked(false);
    setIsProviderBlockClicked(false);
    setIsOverweightResponse(false);
    dispatch(setPullRequestParameters(null));
  }, [activeCodificationType]);

  const handleCloseDrawer = () => {
    setK8sIacData(null);
    setTerraformIacData(null);
    setTfCdkData(null);
    setOtherIacData({});
    setIsOverweightResponse(false);
    closeDrawer();
  };

  const callback = (key) => {
    setActiveTab(key);
    handleCallbackByActiveTab(key, setActiveCodificationType, fetchIacImport, activeLanguage, fetchNewCodify, selectedResources);
  };
  
  const fetchNewCodify = async (type, language, isLanguageSwitcher = false) => {
    setActiveCodificationType(ACTIVE_CODIFICATION_TYPES.naive);
    // check if data already exists, if so, don't fetch again
    if (!isEmpty(otherIacData[type]) && !isLanguageSwitcher){
      if (type === CODIFY_TYPES.cloudformation || type === CODIFY_TYPES.cdk || type === CODIFY_TYPES.cdkl2) {
        const importScript = otherIacData[type][0]?.importScript;
        dispatch(setTerraformCmds([importScript], true));
      } 
      return setIsLoadingOtherIac(false);
    }

    setIsLoadingOtherIac(true);
    const payload = {
      codeProvider: type,
      language,
      accountId: selectedResources[0]?.accountId,
      integrationId: selectedResources[0]?.integrationId,
      isDeleted: isSelectedResourceDeleted,
      FRNs: map(selectedResources, (resource) => resource?.frn),
    }
    const data = await dispatch(fetchGeneratedCodify(payload));
    if (data?.abort) return;
    setOtherIacData({...otherIacData, [type]: data});

    if ((type === CODIFY_TYPES.cloudformation || type === CODIFY_TYPES.cdk) && !isEmpty(data)) {
      const { importScript = '' } = data[0] || {};
      // const cloudformationCmds = `${defaultRunCommand}\n${importScript}`;
      dispatch(setTerraformCmds([importScript], true));
    }
    setIsLoadingOtherIac(false);
  }

  const fetchCloudMigration = async (type) => {
    setIsFetchingIaC(true);
    const payload = {
      codeProvider: "terraform",
      accountId: selectedResources[0]?.accountId,
      integrationId: selectedResources[0]?.integrationId,
      isDeleted: selectedResources[0]?.state === RESOURCE_STATE_TYPES.deleted,
      originCloud: selectedResources[0]?.provider,
      desiredCloud: type,
      FRNs: map(selectedResources, (resource) => resource?.frn),
    }
    const data = await dispatch(fetchGeneratedCloudMigration(payload));
    if (data?.abort) return;
    setTerraformIacData(data)
    setIsFetchingIaC(false);
  }

  const fetchIacImport = async (type, lang, tfType) => {
    setIsFetchingIaC(true);
    setIsImportBlocksClicked(false);
    setIsProviderBlockClicked(false);
    
    const isK8sCodifyTypeButNotProvider = type === "k8s" && selectedResources[0]?.provider !== "k8s";
    const firstResource = selectedResources[0] || {};
    const selectedResourcesWithoutFirst = selectedResources.slice(1) || [];
    const resources = isK8sCodifyTypeButNotProvider ? selectedResources 
    : selectedResourcesWithoutFirst.map((resource = {}) => ({ terraformAssetType: resource.assetType, providerId: resource.integrationId, assetId: resource.assetId, isDeleted: resource.state === RESOURCE_STATE_TYPES.deleted, frn: resource.frn}))

    const payload = {
      accountId: firstResource.accountId,
      resourceType: firstResource.assetType,
      resourceId: firstResource.assetId,
      iacType: type,
      driftNumber: firstResource.driftNumber,
      returnPayloadType: type === CODIFY_TYPES.tfcdk ? lang : "hcl",
      resources,
      provider: firstResource.provider,
      providerId: firstResource.integrationId,
      codificationMode: CODIFICATION_MODES[isEmpty(tfType) ? "naive" : tfType],
      revisionId: firstResource.revisionId,
      isDeleted: firstResource.state === RESOURCE_STATE_TYPES.deleted,
      frn: firstResource.frn,
    }

    const res = await dispatch(fetchGeneratedIaC(payload));
    if (isEmpty(res) || res?.abort){
      return setIsFetchingIaC(false);
    }
    const providerBlocks = res.terraformProviderBlock || '';
    const terraformBlocks = res.terraformImportBlocks;
    const terraformCmds = res.terraformImportCommand;
    const k8sRedacted = res.isK8sRedacted || false;
    const isK8sProvider = firstResource.provider === "k8s";
    const isTerraformCodifyTypes = type === CODIFY_TYPES.terraform || type === CODIFY_TYPES.tfcdk;
    
    //set data by provider
    switch (type) {
      case "k8s": 
        let k8sData = res.hcl
        if (Array.isArray(res.hcl)) {
          k8sData = res.hcl?.join("\n---\n");
        }
        setK8sIacData(k8sData);
        setIsK8sRedacted(k8sRedacted);
        break;
      case CODIFY_TYPES.terraform:
        const tfData = isK8sProvider ? res.hcl : res.codifiedSources;
        setTerraformIacData(isK8sProvider ? tfData : flatten(tfData))
        setIsK8sRedacted(k8sRedacted);
        break;
      case CODIFY_TYPES.tfcdk:
        const tfCdkData = isK8sProvider ? res.hcl : res.codifiedSources;
        setTfCdkData(isK8sProvider ? flatten(tfCdkData).join("\n\n") : flatten(tfCdkData))
      break;
      default: break;
    }
    // additions
    if (!isEmpty(terraformCmds) && isTerraformCodifyTypes) {
      await dispatch(setTerraformCmds(terraformCmds));
      await dispatch(setTerraformCmdsRedacted(res?.isImportCommandRedacted))
    }
    if (!isEmpty(terraformBlocks) && isTerraformCodifyTypes) {
     setImportBlocks(flatten(terraformBlocks))
    }
    if (!isEmpty(providerBlocks)) {
      setProviderBlock(providerBlocks);
    }
    setIsFetchingIaC(false);
  };

  const validateCodifyTerraform = () => {
    if (
      selectedResources?.every((asset) =>
        terraformK8sResources?.includes(asset?.assetType)
      )
    ) {
      return false;
    }
    return true;
  };

  const validateSelectedData = () => {
    if(activeTab === CODIFY_TYPES.terraform && includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType)) {
      return isEmpty(moduleDataCreated[moduleFileSelected])
    }
    switch (activeTab) {
      case CODIFY_TYPES.terraform:
        return (
          isEmpty(terraformIacData) ||
          (activeCodificationType === "MODULE" && isEmpty(moduleData?.HCL))
        );
      case CODIFY_TYPES.tfcdk:
        return isEmpty(tfCdkData) || (activeCodificationType === "MODULE" && isEmpty(moduleData?.HCL));
      case "k8s":
        return isEmpty(k8sIacData);
      default:
        return isEmpty(otherIacData[activeTab])
    }
  };

  const getSelectedData = () => {
    if (isTerraformOrTfCdk && activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules) {
      return selectedModuleCallCode === MODULE_CALL_TABS.yaml ? moduleData?.YamlConfig
      : `${isImportBlocksClicked && !isEmpty(moduleData?.TerraformImportBlocks) ? `${moduleData.TerraformImportBlocks.join("\n")}\n`: ''}${moduleData?.HCL}`;
    }

    if(isTerraformOrTfCdk && includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType)) {
      return moduleDataCreated[moduleFileSelected]
    }

    switch (activeTab) {
      case CODIFY_TYPES.terraform:
        if (includes(keys(MIGRATION_PROVIDERS_TYPES), activeCodificationType)) {
          return !isEmpty(terraformIacData) ? terraformIacData[0].content : ""
        }
        if (isArray(terraformIacData) && !isEmpty(terraformIacData[0]?.HclAsString)){
          const hclArray = map(terraformIacData, (source) => source?.HclAsString);
          return flatten(hclArray).join("");
        }
        return terraformIacData;
      case CODIFY_TYPES.tfcdk:
        if (isArray(tfCdkData) && !isEmpty(tfCdkData[0]?.HclAsString)){
          const hclArray = map(tfCdkData, (source) => source?.HclAsString);
          return flatten(hclArray).join("");
        }
        return tfCdkData;
      case "k8s":
        return k8sIacData;
      default:
        const otherDataByTab = otherIacData[activeTab];
        const isOtherDataArray = isArray(otherDataByTab);
        const selectedData = isOtherDataArray ? (
            otherDataByTab.length === 1 ? otherDataByTab[0].content 
          : otherDataByTab.find((file) => file?.filePath === moduleFileSelected)?.content) // case of multiple codify files
          : ''
        return selectedData;
    }
  };

  const handleSetActiveSmartTab = (type, codifyType, lang) => {
    setActiveCodificationType(type);
    if (type === "modules" || includes(CREATE_MODULE_CODIFICATION_MODES, type)) {
      return setIsFetchingIaC(false);
    }

    if (includes(keys(MIGRATION_PROVIDERS_TYPES), type)) {
      return fetchCloudMigration(MIGRATION_PROVIDERS_TYPES[type]);
    }

    return fetchIacImport(codifyType, lang ? lang : "hcl", type);
  };

  const handleInsertCode = (insertBlock, isToggleClicked, setToggleClickedFunc, isProviderBlock) => {
    const insertAddition = `${isProviderBlock ? insertBlock : insertBlock?.join("\n")}\n`
    const hclString = `${insertAddition}${terraformIacData[0]?.HclAsString}`;
    const terraformClone = terraformIacData;
    if (!isToggleClicked) {
      setToggleClickedFunc(true);
      terraformClone[0].HclAsString = hclString; //import
    }
    else {
      setToggleClickedFunc(false);
      terraformClone[0].HclAsString = terraformClone[0].HclAsString?.replace(insertAddition, "") //destruct
    }
    setTerraformIacData(terraformClone);
  }
  const handleImportBlocks = () => handleInsertCode(importBlocks, isImportBlocksClicked, setIsImportBlocksClicked);
  const handleProviderBlock = () => handleInsertCode(providerBlock, isProviderBlockClicked, setIsProviderBlockClicked, true);

  const getFileType = () => {
    switch (activeTab) {
      case CODIFY_TYPES.terraform:
        if (isModulesAndYamlTab) return MODULE_CALL_TABS.yaml
        return "tf";
      case CODIFY_TYPES.tfcdk: case CODIFY_TYPES.pulumi: case CODIFY_TYPES.cdk: case CODIFY_TYPES.cdkl2: case CODIFY_TYPES.cdk8s:
        return LANGS_SUFFIX[activeLanguage];
      default:
        const isMultipleFilesCodify = isArray(otherIacData[activeTab]) && otherIacData[activeTab].length > 1;
        return isMultipleFilesCodify ? (!isEmpty(moduleFileSelected)?  moduleFileSelected.split(".").pop() : '') : "yaml";
    }
  };

  const handleSetFileExportName = () => {
    if(activeTab === CODIFY_TYPES.pulumi) {
      return CODIFY_TYPES.pulumi;
    } 
    if (isModulesAndYamlTab) return "config"
    // set different file names for create module tab
    const isTerraformCreateModule = activeTab === CODIFY_TYPES.terraform && includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType);
    const isOtherCodifyMultipleFiles = activeTab !== CODIFY_TYPES.terraform && activeCodificationType === ACTIVE_CODIFICATION_TYPES.naive && isArray(otherIacData[activeTab]) && otherIacData[activeTab].length > 1;
    if( (isTerraformCreateModule || isOtherCodifyMultipleFiles) && !isEmpty(moduleFileSelected)) {
      const fileName = moduleFileSelected.split(".");
      return fileName?.length > 0 ? fileName[0] : fileName
    }
    return 'main'
  }

  const handleDownloadZipModuleFiles = async () => {
    setLoadingZipModuleFiles(true);
    alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles })

    const firstResource = selectedResources[0] || {};
    const selectedResourcesWithoutFirst = selectedResources.slice(1) || [];
    const resources = selectedResourcesWithoutFirst.map((resource = {}) => ({ terraformAssetType: resource.assetType, providerId: resource.integrationId, assetId: resource.assetId }))

    const payload = {
      assetType: firstResource.assetType,
      assetId: firstResource.assetId,
      providerId: firstResource.integrationId,
      provider: firstResource.provider,
      resources,
      codificationMode: activeCodificationType === ACTIVE_CODIFICATION_TYPES.createModule ? "CREATE_MODULE_PORTKEY" : "CREATE_MODULE_PORTKEY_WITH_DEPENDENCIES",
    };

    const response = await dispatch(downloadZipModuleFiles(payload));
    if (!response?.req?.ok) {
      return setLoadingZipModuleFiles(false);
    }
    const fileName = "module";
    const downloadUrl = URL.createObjectURL(response?.result);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoadingZipModuleFiles(false);
  }
  
  const renderTabContent = (title, key) => { 
    // Now we only support terraform and tfcdk (no ai capibility) 
    if (revisionIdFromCofifyButton && key !== CODIFY_TYPES.terraform && key !== CODIFY_TYPES.tfcdk) {
      return;
    }
    const icon = codifyIcons(key, themeDark);
    const isTerraform = key === CODIFY_TYPES.terraform;
    return ( 
      <div className="CodifyDrawer__tab row">
        {icon && <img className="CodifyDrawer__tab-icon" src={icon} alt={key} />}
        {title}
        {isTerraform ? <>
          <span>|</span>
          <img className="CodifyDrawer__tab-icon-opentofu" src={codifyIcons("opentofu")} alt={key} />
          <span>OpenTofu</span>
        </>: null}
      </div>
  )}

  const getCodifyTabByProvider = (provider = '', isMultiLanguage = false) => {
    const title = formatCodifyTabName(provider)
    const languagesArr = provider === CODIFY_TYPES.googleCloudSdk ? CODIFY_LANGUAGES.slice(0,3)
    : ([CODIFY_TYPES.tfcdk, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2].includes(provider) ? CODIFY_LANGUAGES
    : CODIFY_LANGUAGES.slice(0, 4));
    
    if (provider === CODIFY_TYPES.terraform || provider === CODIFY_TYPES.tfcdk) {
      return (
        <TabPane tab={renderTabContent(title, provider)} key={provider}>
          <div className={`CodifyDrawer__content-body ${isMultiLanguage ? 'switcherTab' : ''}`}>
              {isMultiLanguage &&
                <LanguageSwitcher
                  activeTab={activeLanguage}
                  handleSetActiveTab={(lang) => {
                  setActiveLanguage(lang);
                  fetchIacImport(provider, lang)
                }}
                typeArr={languagesArr}
                isFetchingIaC={isFetchingIaC}/>
                }
                <SmartCodifyTab
                  codifyType={provider}
                  handleSetActiveTab={(type) =>
                    handleSetActiveSmartTab(type, provider, provider === CODIFY_TYPES.tfcdk ? activeLanguage : null)
                  }
                  isFetchingIaC={isFetchingIaC}
                  iacData={mapData[provider]}
                  activeType={activeCodificationType}
                  selectedResources={selectedResources}
                  isImportBlocksClicked={isImportBlocksClicked}
                  handleOverweightData={(isOverweight) => setIsOverweightResponse(isOverweight)}
                />
              </div>
        </TabPane>
      )
    }
    return (
      <TabPane tab={renderTabContent(title, provider)} key={provider}>
        <div className={`CodifyDrawer__content-body ${isMultiLanguage ? 'switcherTab' : ''}`}>
       {isMultiLanguage &&
            <LanguageSwitcher
              activeTab={activeLanguage}
              handleSetActiveTab={(lang) => {
                setActiveLanguage(lang);
                fetchNewCodify(provider, lang, true)
              }}
              typeArr={languagesArr}
              isFetchingIaC={isLoadingOtherIac}/>
        }
        <SmartCodifyTab
            codifyType={provider}
            handleSetActiveTab={(type) => null}
            isFetchingIaC={isLoadingOtherIac}
            iacData={otherIacData?.[provider]}
            activeType={activeCodificationType}
            selectedResources={selectedResources}
            hideNonNaiveTypes
            isCodifyAI
            handleOverweightData={(isOverweight) => setIsOverweightResponse(isOverweight)}
          />
        </div>
      </TabPane>
    )
  }

  const getTabs = (provider) => {
    if (providers[provider]?.codifyDefault === "terraform") {
      if (provider === PROVIDERS.aws) {
        return (
          <Tabs
            defaultActiveKey={CODIFY_TYPES.terraform}
            onChange={callback}
            activeKey={activeTab}
            tabBarStyle={{ padding: "0" }}
          >
            {getCodifyTabByProvider(CODIFY_TYPES.terraform)}
            {getCodifyTabByProvider(CODIFY_TYPES.tfcdk, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.cloudformation)}
            {getCodifyTabByProvider(CODIFY_TYPES.cdk, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.cdkl2, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.crossplane)}
            {getCodifyTabByProvider(CODIFY_TYPES.ansible)}
          </Tabs>
        );
      }
      if (provider === PROVIDERS.gcp) {
        return (
          <Tabs
            defaultActiveKey={CODIFY_TYPES.terraform}
            onChange={callback}
            activeKey={activeTab}
            tabBarStyle={{ padding: "0" }}
          >
            {getCodifyTabByProvider(CODIFY_TYPES.terraform)}
            {getCodifyTabByProvider(CODIFY_TYPES.tfcdk, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.configConnector)}
            {getCodifyTabByProvider(CODIFY_TYPES.googleCloudSdk, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.crossplane)}
            {getCodifyTabByProvider(CODIFY_TYPES.ansible)}
          </Tabs>
        );
      }
      if (provider === PROVIDERS.azurerm) {
        return (
          <Tabs
            defaultActiveKey={CODIFY_TYPES.terraform}
            onChange={callback}
            activeKey={activeTab}
            tabBarStyle={{ padding: "0" }}
          >
            {getCodifyTabByProvider(CODIFY_TYPES.terraform)}
            {getCodifyTabByProvider(CODIFY_TYPES.tfcdk, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.arm)}
            {getCodifyTabByProvider(CODIFY_TYPES.bicep)}
            {getCodifyTabByProvider(CODIFY_TYPES.crossplane)}
            {getCodifyTabByProvider(CODIFY_TYPES.ansible)}
          </Tabs>
        );
      }
      return (
        <Tabs
          defaultActiveKey="terraform"
          onChange={callback}
          activeKey={activeTab}
          tabBarStyle={{ padding: "0" }}
        >
            {getCodifyTabByProvider(CODIFY_TYPES.terraform)}
            {getCodifyTabByProvider(CODIFY_TYPES.tfcdk, true)}
            {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
        </Tabs>
      );
    }
    //k8s
    return (
      <Tabs
        defaultActiveKey="Manifest"
        onChange={callback}
        activeKey={activeTab}
        tabBarStyle={{ padding: "0" }}
      >
        <TabPane tab={renderTabContent("Manifest", CODIFY_TYPES.manifest)} key="k8s">
          <div className="CodifyDrawer__content-body center">
            <TerraformCodification
              isFetchingIaC={isFetchingIaC}
              iacData={k8sIacData}
              language="yaml"
            />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              {validateCodifyTerraform() ? (
                <Tooltip
                  placement="top"
                  title="Not Supported"
                >
                  {renderTabContent("Terraform", CODIFY_TYPES.terraform)}
                </Tooltip>
              ) : renderTabContent("Terraform", CODIFY_TYPES.terraform)}
            </span>
          }
          key="terraform"
          disabled={validateCodifyTerraform()}
        >
          <div className={`CodifyDrawer__content-body`}>
            <SmartCodifyTab
              codifyType="terraform"
              handleSetActiveTab={(type) =>
                handleSetActiveSmartTab(type, "terraform")
              }
              isFetchingIaC={isFetchingIaC}
              iacData={terraformIacData}
              activeType={activeCodificationType}
              selectedResources={selectedResources}
              hideNonNaiveTypes={true}
              isImportBlocksClicked={isImportBlocksClicked}
              handleOverweightData={(isOverweight) => setIsOverweightResponse(isOverweight)}
            />
          </div>
        </TabPane>
        {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
        {getCodifyTabByProvider(CODIFY_TYPES.helm)}
        {getCodifyTabByProvider(CODIFY_TYPES.cdk8s, true)}
      </Tabs>
    );
  };

  return (
    <Drawer
      title={null}
      placement="right"
      closable={false}
      onClose={handleCloseDrawer}
      visible={visible}
      key="right"
      className="CodifyDrawer"
      width="75vw"
      maskStyle={{ backgroundColor: "rgba(0,0,0,.65)" }}
      zIndex={1001}
    >
      <div className="CodifyDrawer__content">
        <span className="title CodifyDrawer__content-title">
          Infrastructure Codification
        </span>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={handleCloseDrawer}
          className="PullRequestForm__content-close"
        />

        {!isEmpty(selectedResources) && getTabs(selectedResources[0].provider)}

        <div className="CodifyDrawer__content-footer row between">
          {!includes([ACTIVE_CODIFICATION_TYPES.awsMigration,
            ACTIVE_CODIFICATION_TYPES.azureMigration, ACTIVE_CODIFICATION_TYPES.googleMigration], activeCodificationType) && (
            <div className="row g10">
              <AppToggle
                text="Import Blocks"
                checked={isImportBlocksClicked}
                toggleChecked={handleImportBlocks}
                disabled={isEmpty(terraformIacData) || activeTab !== CODIFY_TYPES.terraform || includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType) ||
                  isSelectedResourceDeleted || isK8sSelectedProvider ||
                (activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules && isEmpty(moduleData?.TerraformImportBlocks))}
              />
              <AppToggle
                text="Provider Block"
                checked={isProviderBlockClicked}
                toggleChecked={handleProviderBlock}
                disabled={ isEmpty(terraformIacData) || activeTab !== CODIFY_TYPES.terraform || isSelectedResourceDeleted || 
                [ACTIVE_CODIFICATION_TYPES.createModule, ACTIVE_CODIFICATION_TYPES.createModuleDependencies, ACTIVE_CODIFICATION_TYPES.modules].includes(activeCodificationType) || isK8sSelectedProvider }
              />
            </div>
          )}
          <div className="row g10">
          {!includes([ACTIVE_CODIFICATION_TYPES.createModule, ACTIVE_CODIFICATION_TYPES.createModuleDependencies, ACTIVE_CODIFICATION_TYPES.awsMigration,
            ACTIVE_CODIFICATION_TYPES.azureMigration, ACTIVE_CODIFICATION_TYPES.googleMigration], activeCodificationType) && (
          <div className="CodifyDrawer__content-footer-importCmds">
            <AppBtn
              plain
              text={[CODIFY_TYPES.cloudformation, CODIFY_TYPES.tfcdk, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2].includes(activeTab) ? "Import Script" : "Import Commands"}
              onClick={() => handleOpenTerraformImport(activeTab)}
              icon={<ImportCmdsIcon />}
              disabled={![CODIFY_TYPES.terraform, CODIFY_TYPES.cloudformation, CODIFY_TYPES.tfcdk, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2].includes(activeTab) || 
                 isK8sSelectedProvider ||
                (isEmpty(terraformIacData) && isEmpty(tfCdkData) && isEmpty(otherIacData?.[CODIFY_TYPES.cloudformation])) ||
                isSelectedResourceDeleted ||
                (activeTab === CODIFY_TYPES.cloudformation && isEmpty(otherIacData?.[CODIFY_TYPES.cloudformation]?.[0]?.importScript)) || 
                (activeTab === CODIFY_TYPES.cdk && isEmpty(otherIacData?.[CODIFY_TYPES.cdk]?.[0]?.importScript)) ||
                (activeTab === CODIFY_TYPES.cdkl2 && isEmpty(otherIacData?.[CODIFY_TYPES.cdkl2]?.[0]?.importScript))
              }/>
          </div>)}
              <ActionBtn
                text="Download"
                disabled={validateSelectedData()}
                action="download"
                icon="donwload"
                fileType={getFileType()}
                fileName={handleSetFileExportName()}
                stringToAction={getSelectedData()}
                onClickDifferentActions={() => alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles, isK8sRedacted, isK8sSelectedProvider })}
                refresh={visible}
              />
             {includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType) && <AppBtn
                text="Export All"
                icon={<FontAwesomeIcon icon="folder-open" />}
                onClick={handleDownloadZipModuleFiles}
                disabled={isEmpty(moduleDataCreated)}
                loading={loadingZipModuleFiles}
              />}
              <ActionBtn
                text="Copy"
                icon="copy"
                disabled={validateSelectedData()}
                action="copy"
                onClickDifferentActions={() => alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles, isK8sRedacted, isK8sSelectedProvider })}
                stringToAction={getSelectedData()}
                refresh={visible}
              />
          
              {<AppBtn
                text="Pull request"
                onClick={() => {
                  const isCreateModuleTab = includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType);
                  const isNaivePreparedContent = activeCodificationType === ACTIVE_CODIFICATION_TYPES.naive && !isTerraformOrTfCdk
                  const isCreateModulePreparedContent = isCreateModuleTab && !isEmpty(moduleDataCreated);
                  const preparedContent = isNaivePreparedContent ? mapData[activeTab]
                  : (isCreateModulePreparedContent ? Object.entries(moduleDataCreated).map(([filePath, content] = []) => ({ filePath, content }))
                  : activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules ? getModuleCallPreparedContent(moduleData, isImportBlocksClicked, selectedModuleCallCode)
                  : null);
                    dispatch(
                      setPullRequestParameters({
                        ...pullReqParams,
                        codificationMode: CODIFICATION_MODES[activeCodificationType],
                        codifyType: isCreateModuleTab ? activeCodificationType : undefined,
                        withImportBlocks: isImportBlocksClicked,
                        withProviderBlock: isProviderBlockClicked,
                        iacType: activeTab === CODIFY_TYPES.tfcdk ? CODIFY_TYPES.tfcdk : null,
                        language: activeTab === CODIFY_TYPES.tfcdk ? activeLanguage : null,
                        preparedContent,
                        fileExtension: activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules ? "tf" : getFileType(),
                      })
                    );
                  handleOpenPullRequest();
                  alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles, isK8sRedacted, isK8sSelectedProvider})
                }}
                icon={<FontAwesomeIcon icon={faGitAlt} />}
                disabled={
                  isViewer 
                  || !includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType) && isEmpty(mapData[activeTab])
                  || includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType) && isEmpty(moduleDataCreated)}
              />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CodifyDrawer;