import React from "react";
import { ReactComponent as ThinkerbellIcon } from "../../../Images/general_icons/thinkerbell/thinkerbell_logo.svg";
import { Tooltip } from "antd";

import "./remediationAllBtn.scss";

const RemediationAllBtn = ({ t, handleRemidiationAllDiagnostics }) => {
  return (
    <Tooltip title={t("diagnosticRemediation.btnAll")}>
      <span className="RemediationAllBtn row g5" onClick={handleRemidiationAllDiagnostics}>
        <ThinkerbellIcon className="RemediationAllBtn-icon" />
          <span className="RemediationAllBtn-text">
            {t("diagnosticRemediation.btnAll")}
          </span>
      </span>
    </Tooltip>
  );
};

export default RemediationAllBtn;
