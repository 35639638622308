export const providers = {
    aws: {
        insightPrefix: "aws",
        colorClass: "orange",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: true,
        integrationPath: "aws",
        providerVersion: "4.8.0",
    },
    azurerm: {
        insightPrefix: "azurerm",
        colorClass: "blue",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "azure",
        providerVersion: "3.68.0",
    },
    k8s: {
        insightPrefix: "kubernetes",
        colorClass: "blue",
        showIntegrationSync: true,
        codifyDefault: "manifest",
        showCodifyComingSoon: false,
        integrationPath: "k8s",
        providerVersion: "1.28.0",
    },
    akamai: {
        insightPrefix: "akamai",
        colorClass: "orange",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "akamai",
        providerVersion: "4.1.0",
    },
    datadog: {
        insightPrefix: "datadog",
        colorClass: "darkPurple",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "datadog",
        providerVersion: "3.11.0",
    },
    github: {
        insightPrefix: "github",
        colorClass: "black",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "github-provider",
        providerVersion: "4.26.0",
    },
    okta: {
        insightPrefix: "okta",
        colorClass: "darkBlue",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "okta",
        providerVersion: "3.28.0",
    },
    gcp: {
        insightPrefix: "google",
        colorClass: "green",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "gcp-provider",
        providerVersion: "4.47.0",
    },
    newrelic: {
        insightPrefix: "newrelic",
        colorClass: "darkGreen",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "newrelic",
        providerVersion: "2.48.0",
    },
    cloudflare: {
        insightPrefix: "cloudflare",
        colorClass: "orange",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "cloudflare",
        providerVersion: "3.35.0",
    },
    ns1: {
        insightPrefix: "ns1",
        colorClass: "black",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "ns1",
        providerVersion: "2.0.2",
    },
    pagerduty: {
        insightPrefix: "pagerduty",
        colorClass: "green",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "pagerduty",
        providerVersion: "2.15.3",
    },
    mongodbatlas: {
        insightPrefix: "mongodbatlas",
        colorClass: "black",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "mongodbatlas",
        providerVersion: "1.10.2",
    },
    vault: {
        insightPrefix: "vault",
        colorClass: "black",
        showIntegrationSync: true,
        codifyDefault: "terraform",
        showCodifyComingSoon: false,
        integrationPath: "vault",
        providerVersion: "4.4.0",
    }
};
