export const VAULT_FORM = {
  permission: `
    path "*"
    {
      capabilities = ["read","list"]
    }

    path "auth/token/renew-self"
    {
      capabilities = ["update"]
    }
    
    path "auth/token/create"
    {
      capabilities = ["update", "create"]
    }   
    `
};

