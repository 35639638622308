import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAccountIntegrations } from "../../../redux/actions/onbordingActions";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { clouds } from "../../../utils/icons";
import Loading from "../../../shared/loading/loading";
import IntegrationCard from "../integrationCard/integrationCard";
import { onboardingEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";

import "./providerStep.scss";
import { PROVIDERS } from "../../../consts/general";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";

const ProviderStep = ({ openComingSoonAlert }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const integrations = useSelector(
    (state) => state.onbordingReducer.integrations
  );

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    handleFetchProviders();
  }, []);

  const handleFetchProviders = async () => {
    await dispatch(getAccountIntegrations());
    setLoading(false);
  };

  const providers = [
    {
      icon: clouds("aws", themeDark),
      title: "AWS",
      onClick: () => history.push("/integrations/aws-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.aws),
    },
    {
      icon: clouds("azure", themeDark),
      title: "Azure",
      onClick: () => history.push("/integrations/azure-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.azurerm),
    },
    {
      icon: clouds("gcp", themeDark),
      title: "Google Cloud",
      onClick: () => history.push("/integrations/gcp-provider-integration"),
      coming_soon: false,
      connected: !isEmpty(
        filter(integrations?.gcs, (item) => item?.source === "GCP")
      ),
    },
    {
      icon: clouds("k8s", themeDark),
      title: "K8s",
      onClick: () => history.push("/integrations/k8s-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.k8s),
    },
    {
      icon: clouds("datadog", themeDark),
      title: "Datadog",
      onClick: () => history.push("/integrations/datadog-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.datadog),
    },
    {
      icon: clouds("akamai", themeDark),
      title: "Akamai",
      onClick: () => history.push("/integrations/akamai-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.akamai),
    },
    {
      icon: clouds("github", themeDark),
      title: "GitHub",
      onClick: () => setConfirmationVisible(true),
      coming_soon: false,
      connected: !isEmpty(integrations?.github),
    },
    {
      icon: clouds("okta", themeDark),
      title: "Okta",
      onClick: () => history.push("/integrations/okta-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.okta),
    },
    {
      icon: clouds("newrelic", themeDark),
      title: "New Relic",
      onClick: () => history.push("/integrations/newrelic-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.newrelic),
    },
    {
      icon: clouds("cloudflare", themeDark),
      title: "Cloudflare",
      onClick: () => history.push("/integrations/cloudflare-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.cloudflare),
    },
    {
      icon: clouds(PROVIDERS.ns1, themeDark),
      title: "NS1",
      onClick: () => history.push("/integrations/ns1-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.ns1),
    },
    // {
    //   icon: clouds(PROVIDERS.pagerduty, themeDark),
    //   title: "PagerDuty",
    //   onClick: () => history.push("/integrations/pagerduty-provider-integration"),
    //   coming_soon: true,
    //   connected: !isEmpty(integrations?.pagerduty),
    // },
    {
      icon: clouds(PROVIDERS.mongodbatlas, themeDark),
      title: "MongoDB Atlas",
      onClick: () => history.push("/integrations/mongodbatlas-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.mongodbatlas),
    },
    {
      icon: clouds(PROVIDERS.vault, themeDark),
      title: "Vault",
      onClick: () => history.push("/integrations/vault-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.vault),
    },
    {
      icon: clouds("oracle", themeDark),
      title: "Oracle Cloud",
      onClick: () => openComingSoonAlert("Oracle Cloud"),
      coming_soon: false,
    },
    {
      icon: clouds("alibaba", themeDark),
      title: "Alibaba Cloud",
      onClick: () => openComingSoonAlert("Alibaba Cloud"),
      coming_soon: false,
    },
  ];

  const renderProviders = () => {
    return map(providers, (item) => {
      return (
        <IntegrationCard
          key={item?.title}
          icon={item?.icon}
          title={item?.title}
          onClick={() => {
            item?.onClick();
            return sendEvent(onboardingEvents.integrationClick, {
              integration: item?.title,
            });
          }}
          coming_soon={item?.coming_soon}
          connected={item?.connected}
        />
      );
    });
  };
  return (
    <>
      <div className="ProviderStep center">
        {loading ? (
          <div className="tab-page center">
            <Loading />
          </div>
        ) : (
          <div className="ProviderStep__list">{renderProviders()}</div>
        )}
      </div>
      <ConfirmationModal
        visible={confirmationVisible}
        cancelBtnText="Ok"
        hidePrimaryButton
        handleClose={() => history.push("/integrations/github-provider-integration")}
        title="Attention" 
        description={(
          <span>
           This integration is for users who manage GitHub with IaC/Terraform, or looking to do that (it will fetch GitHub entities into the asset inventory). 
           <b>If you are looking to integrate your GitHub as a Version Control System (to access your Code), please wait for the right step in this wizard.</b>
          </span>
        )}
      />
    </>
  );
};

export default ProviderStep;
