import React, { useEffect, useState } from "react";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";
import EventCenterFilters from "../../components/eventCenter/EventCenterFilters/EventCenterFilters";
import { useTranslation } from "react-i18next";
import { ReactComponent as CelandarIcon } from "../../Images/general_icons/celandar.svg";
import { ReactComponent as ArrowIcon } from "../../Images/general_icons/arrow.svg";

import CodeDiff from "../../shared/codeDiff/codeDiff";
import { Divider, Tooltip } from "antd";
import { historyFlag } from "../../components/inventory/assetFlagsFilter/flagIcons";
import { formatAwsStringWithUnderscore, renderEllipsis } from "../../utils/formatting";

import AssetTypeImage from "../../shared/assetTypeImage/assetTypeImage";
import { useDispatch, useSelector } from "react-redux";

import { clearData, getEventsData } from "../../redux/actions/eventCenterActions";
import Loading from "../../shared/loading/loading";
import { getTimestampByTimeFilter, handleOpenAssetInInventory, handleSetComapreBlock } from "../../components/eventCenter/eventCenterHelper";
import AppEmpty from "../../shared/appEmpty/appEmpty";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import { useMemo } from "react";

import { clouds } from "../../utils/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LongLoader from "../../shared/longLoader/longLoader";
import "./eventCenter.scss";

const dateTimeSecondsFormat = "MMM DD YYYY, HH:mm";
const mutationIcon = historyFlag(true, false);

const CHUNK_SIZE = 10;

const EventCenter = () => {
    const { t: tEvents } = useTranslation('event-explorer');
    const [loading, setLoading] = useState(false);
    const [pullLoading, setPullLoading] = useState(false);
    const [didMount, setDidMount] = useState(false);

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const data = useSelector((state) => state.eventCenterReducer.events) || [];
    const totalEvents = useSelector((state) => state.eventCenterReducer.totalEvents);
    const filters = useSelector((state) => state.eventCenterReducer.filters) || {};
    const providerIntegrations = useSelector((state) => state.globalAppReducer.clouds) || {};

    const allIntegs = useMemo(() => {
        return Object.entries(providerIntegrations || {}).reduce((acc, [provider, integrationsArr = []]) => {
            integrationsArr.forEach(({id = "", name = "" } = {}) => acc[id] = { name, id, provider });
            return acc;
        }, {});
    }, [providerIntegrations]);
    
    const dispatch = useDispatch();

    const hasMoreToPull = data.length < totalEvents;

    useEffect(() => {
        initFetch();
        return () => clearEventsData();
    }, []);

    useEffect(() => {
        if (!didMount) return;
        fetchDataWithFilters();
    }, [filters]);

    const fetchDataWithFilters = async() => {
        setLoading(true);
        await fetchData(true);
        setLoading(false);
    };

    const clearEventsData = async () => await dispatch(clearData());
    const initFetch = async () => {
        setLoading(true);
        await Promise.all([getAllIntegrations(), fetchData()]);
        setLoading(false);
        setDidMount(true);
    }

    const handlePull = async() => {
        if (hasMoreToPull) {
            setPullLoading(true);
            await fetchData();
            setPullLoading(false);
            const element = document.getElementById("EventCenter__scrollIcon");
            element?.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }

    const getAllIntegrations = async() => {
        if (!isEmpty(providerIntegrations)) return;
        await dispatch(getAllClouds());

    }
    const fetchData = async (isFiltersChange = false) =>  {
        const limit = isFiltersChange ? CHUNK_SIZE : (data?.length || 0) + CHUNK_SIZE;
        const eventsFilters = { ...filters, from: getTimestampByTimeFilter(filters?.selectedTime), to: new Date().getTime()};
        delete eventsFilters.selectedTime;
        await dispatch(getEventsData({ filters: eventsFilters, skip: 0, limit }));
    };
    return (
        <div className="EventCenter col g15">
            <div className="row g40">
                <span className="font-18 bold">{tEvents("timeline")}</span>
                <EventCenterFilters tEvents={tEvents} allIntegs={allIntegs} />
            </div>
           
            <div className="EventCenter__content col g10">
                <div className="EventCenter__content-header">
                    <CelandarIcon className="EventCenter__content-header-icon" />
                    <span>{tEvents("filters.data-source")}</span>
                    <span>{tEvents("filters.location")}</span>
                    <span>{tEvents("filters.asset-type")}</span>
                    <span>{tEvents("filters.event-details")}</span>
                </div>
                <div className="EventCenter__content-line" />
                <ArrowIcon id="EventCenter__scrollIcon" className={`EventCenter__content-scrollIcon ${pullLoading ? "loading": ""} ${hasMoreToPull ? "" : "disabled"}`} onClick={() => handlePull()}
              />
                <div className="EventCenter__content-list col g15" id="content-scroll">
                    {loading ? <div className="tab-page center"><LongLoader msg1={tEvents("loadingMessage")} loading={loading} duration={10000} withTypingDots customLoader={<Loading />}/></div>
                    : data?.length ? data.map((event = {}) => {
                        const { integrationId: integId = "" } = event;
                        const { name: integrationName = "", provider = "" } = allIntegs[integId] || {};
                      return (
                        <div className="row EventCenter__content-list-item" key={uuidv4()}>
                            <div className="EventCenter__content-list-item-fields" key={uuidv4()}>
                                <div className="row g10">
                                    <span>{moment(event.timestamp).format(dateTimeSecondsFormat)}</span>
                                    {mutationIcon}
                                    <Tooltip title={tEvents("viewInInventory")}>
                                        <FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => handleOpenAssetInInventory(e, event)}/>
                                    </Tooltip>
                                    <Divider className="EventCenterFilters-divider" type="vertical" />
                                </div>
                                <div className="row g8">
                                    {provider && <img className="EventCenter__content-list-item-icon" src={clouds(provider, themeDark)} />}
                                    <Tooltip title={integrationName.length > 23 ? integrationName : ""}><span>{renderEllipsis(integrationName, 23)}</span></Tooltip>
                                </div>
                                <span className="center">{event.location}</span>
                                <div className="row g8">
                                    <AssetTypeImage assetType={event.assetType} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>
                                    <span>{formatAwsStringWithUnderscore(event.assetType)}</span>
                                </div>
                            </div>
                            <CodeDiff lang="text" newValue={handleSetComapreBlock(event.currentRevision)} oldValue={handleSetComapreBlock(event.requestedRevision)}/>
                        </div>
                      )  
                    }) : (
                    <div className="tab-page center">
                        <AppEmpty text="No data" customStyle="code" />
                     </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default EventCenter;
