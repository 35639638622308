export const getValidationRules = (endpoint = '') => {
    if (endpoint?.startsWith('https://') || endpoint?.startsWith('http://')) {
      return [
        {
          required: true,
          message: "Endpoint is required",
        },
        {
          pattern: /^(https?:\/\/)((?!-)(?:[a-zA-Z\d-]+\.)+[a-zA-Z]{2,}(?::\d{2,5})?(?:[\/\w .-]*)*\/?|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):\d+)$/,
          message: 'Please enter a valid URL or IP address',
        }
      ];
    }
    return [
      {
        required: true,
        message: "Endpoint is required",
      },
      {
        pattern: /^(https?:\/\/)/,
        message: 'Please enter a valid endpoint starting with http:// or https://',
      },
    ];
  };