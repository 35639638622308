import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createProviderIntegration } from "../../../redux/actions/integrationsActions";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import { clouds } from "../../../utils/icons";
import VaultForm from "./vaultForm";
import { PROVIDERS } from "../../../consts/general";
import "./vaultIntegration.scss";

const VaultIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const isDarkTheme = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Vault Integration setup",
      content_title: "Vault Integration",
      content: (
        <VaultForm
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const req = await dispatch(
          createProviderIntegration(PROVIDERS.vault, {
            name: wizardState?.name,
            url: wizardState?.url,
            username: wizardState?.username,
            password: wizardState?.password,
          })
        );
        setLoadingSubmit(false);
        if (!req?.ok) {
          throw new Error(`Failed to create Vault integration.`);
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
          subTitle="The Resources from the Integration will take up to one hour to appear in the inventory"
          doesTakeTime
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="VaultIntegration">
      <AppWizard
        iconSrc={clouds(PROVIDERS.vault, isDarkTheme)}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default VaultIntegration;
