import React from "react";
import isEmpty from "lodash/isEmpty";
import { Form, Input } from "antd";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";
import { DESCRIPTIONS } from "../../../../consts/general";
import { ReactComponent as EventDriven } from "../../../../Images/general_icons/eventdrivenGrey.svg";
import { ReactComponent as AdvancedSettingsIcon } from "../../../../Images/general_icons/advance_settings_icon.svg";
import AppToggle from "../../../../shared/appToggle/appToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import EditableTagGroup from "../../../../shared/editableTagGroup/editableTagGroup";

const GCP_TERRAFORM_GUIDE_URL = "https://docs.firefly.ai/firefly-documentation-portal/user-guides/integrations/integrate-your-providers-and-tools/integrate-your-data-sources/integrate-google-cloud/integrate-google-cloud-using-terraform#discovering-multiple-projects-in-this-integration";

const GcpProviderTerraformInstructions = ({
  setSubmitDisabled,
  wizardState,
  setWizardState,
}) => {

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name } = formValues;
    if (isEmpty(name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const [form] = Form.useForm();
  const layout = {};

  const renderCode = (isOneLine, text) => {
    let hcl = wizardState?.code;
    if (isOneLine) {
      hcl = text;
    }
    return <DarkCopyBox text={hcl} />;
  };

  const handleToggleField = (fieldName) => {
    setWizardState({...wizardState,...form.getFieldsValue(),[fieldName]:  !form.getFieldValue(fieldName)});
    form.setFieldsValue({ [fieldName]:  !form.getFieldValue(fieldName) });
  }
  const handleChangeRules = (rules) => {
    const regexExcludeProjectsDiscovery = rules;
    setWizardState({...wizardState,...form.getFieldsValue(),regexExcludeProjectsDiscovery});
    form.setFieldsValue({ regexExcludeProjectsDiscovery});
  }
  return (
    <div className="GcpProviderTerraformInstructions col">
      <div className="GcpProviderTerraformInstructions__intro col">
        <div>
          <span className="dark-blue bold form-label">Before you begin</span>
          <ul className="dark-blue GcpProviderTerraformInstructions__intro-list">
            <li key="1">
              <span className="dark-blue" style={{ lineHeight: "1.5em" }}>
                Use{" "}
                <a
                  href="https://www.terraform.io/cli/commands/0.13upgrade"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Terraform v0.13 {" "}
                </a>
                or later. To check your version of Terraform, enter:
                {renderCode(true, "terraform --version")}
              </span>
            </li>
            <li key="2">
              <span className="dark-blue">
                <a
                  href="https://cloud.google.com/sdk/docs/install"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Install gcloud CLI.
                </a>
              </span>
            </li>
            <li key="3">
              <span className="dark-blue" style={{ lineHeight: "1.5em" }}>
                To verify these requirements, run the command in your terminal:
                <br />
                <div className="GcpProviderTerraformInstructions__cmds-item col">
                  {renderCode(true, "gcloud version && terraform --version")}
                </div>
              </span>
            </li>
          </ul>
        </div>
        <Form
          {...layout}
          name="gcp-provider-tf-form"
          form={form}
          initialValues={{
            name: wizardState.name || "Google Cloud",
            isEventDrivenDisabled:  wizardState.isEventDrivenDisabled ?? false,
            isProd: wizardState.isProd ?? false,
            isIacAutoDiscoverDisabled: wizardState.isIacAutoDiscoverDisabled ?? false,
            regexExcludeProjectsDiscovery: wizardState.regexExcludeProjectsDiscovery ?? [],
            projectId: wizardState.projectId,
          }}
          className="AppWizard__content-body-form"
          onValuesChange={(changedValues, allValues) => {
            setWizardState({
              ...wizardState,
              ...allValues,
            });
            checkValidation(allValues);
          }}
        >
          <Form.Item
            label="Project ID"
            name="projectId"
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input placeholder="Enter Project ID" />
          </Form.Item>
          <Form.Item
            label="Integration Nickname"
            name="name"
            rules={[
              {
                required: true,
                message: "Integration Nickname is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input placeholder="Google Cloud Account Nickname" />
          </Form.Item>
        <div className="GcpProviderTerraformInstructions__advanced-options">
          <div className="GcpProviderTerraformInstructions__advanced-options-title">
            <AdvancedSettingsIcon />
            Advanced Options
          </div>
          <div>
          <Form.Item name="isEventDrivenDisabled">
            <span className="GcpProviderTerraformInstructions__custom-toggle mb-0">
              <AppToggle checked={!form.getFieldValue('isEventDrivenDisabled')} toggleChecked={() => handleToggleField('isEventDrivenDisabled')} />
              <div className="col g5">
                <span className={`GcpProviderTerraformInstructions__custom-toggle__title ${'checked'}`}>
                  <span className="toggle-label form-label">Event-driven</span>
                  <EventDriven />
                </span>
                <span className="GcpProviderTerraformInstructions__custom-toggle__subtitle">
                  {DESCRIPTIONS.gcpEventDriven}
                </span>
              </div>
            </span>
          </Form.Item>
            <div>
              <div className="col g5">
                <span className="bold form-label">Multiple Project Discovery</span>
                <span className="GcpProviderTerraformInstructions__custom-toggle__subtitle">To integrate multiple projects in this integration, complete the procedure {" "}
                  <a href={GCP_TERRAFORM_GUIDE_URL} target="_blank" rel="noreferrer">here</a>.
                </span>
                <span className="GcpProviderTerraformInstructions__custom-toggle__subtitle">To exclude part of the projects under this service account, use the Regex rules below.</span>
              </div>
              <Form.Item
                name="regexExcludeProjectsDiscovery"
                label="Regex rules"
                style={{ flexDirection: "column" }}
              >
                <EditableTagGroup onChange={handleChangeRules}  />
              </Form.Item>
            </div>
            <Form.Item name="isIacAutoDiscoverDisabled">
            <span className="GcpProviderTerraformInstructions__custom-toggle">
              <AppToggle checked={!form.getFieldValue('isIacAutoDiscoverDisabled')} toggleChecked={() => handleToggleField('isIacAutoDiscoverDisabled')} />
              <span>
              <div className={`GcpProviderTerraformInstructions__custom-toggle__title ${'checked'}`}>
                  <span className="toggle-label form-label">IaC Discovery</span>
                </div>
                <div className="GcpProviderTerraformInstructions__custom-toggle__subtitle">
                  Allow Firefly to scan your GCS (buckets) and discover all Terraform state files.
                </div>
              </span>
            </span>
            </Form.Item>
            <Form.Item name="isProd">
            <span className="GcpProviderTerraformInstructions__custom-toggle">
              <AppToggle checked={form.getFieldValue('isProd')} toggleChecked={() => handleToggleField('isProd')} />
              <span>
                <div className={`GcpProviderTerraformInstructions__custom-toggle__title ${''}`}>
                  <span className="toggle-label form-label">Mark as Production</span>
                  <FontAwesomeIcon icon={faFlag} />
                </div>
                <div className="GcpProviderTerraformInstructions__custom-toggle__subtitle">
                  This account will be marked as production. You can edit this at any time in the <b>Integrations</b> window. Once done, you will be able to view the production accounts separately in the <b>Dashboard</b>.
                </div>
              </span>
            </span>
            </Form.Item>
          </div>
        </div>
        </Form>
      </div>
    </div>
  );
};

export default GcpProviderTerraformInstructions;
