import React, { useState } from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { nicknameRegex, validate } from "../../../utils/validations";
import DarkCopyBox from "../../../shared/darkCopyBox/darkCopyBox";
import { VAULT_FORM } from "../../../consts/form";
import "./vaultForm.scss";
import { useTranslation } from "react-i18next";
import { getValidationRules } from "../../../utils/integrations";

const VaultForm = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const [url, setUrl] = useState('');
  const { t } = useTranslation("integrations");

  const layout = {};

  //need refactor use one funcation
  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, url, username, password  } = formValues;

    if (_.isEmpty(name) || _.isEmpty(url) || _.isEmpty(username) || _.isEmpty(password)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }

    if(form.getFieldError('url').length > 0 || form.getFieldError('username').length > 0 || form.getFieldError('password').length > 0) {
      formValid = false;
    }

    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="VaultForm col">
      <div className="col">
        <span className="dark-blue bold">{t("vaultForm.procedure")}</span>
        <ol className="VaultForm__intro-orderedlist">
          <li>
            {t("vaultForm.steps.0")}
            <ol type="a">
              <li className="mgTtm5">{t("vaultForm.steps.1")}</li>
              <li>
                {t("vaultForm.steps.2")}{" "}
                <span className="purple-flag mgTtm5 font-12">
                  {t("vaultForm.listOfPermission")}
                </span>
              </li>
              <div className="mgTtm10">
                <DarkCopyBox text={VAULT_FORM.permission} />
              </div>
            </ol>
          </li>
          <li>
            {t("vaultForm.steps.3")}{" "}
            <span className="purple-flag font-12">
              {t("vaultForm.userpass")}
            </span>{" "}
            {t("vaultForm.steps.4")}
            <ol type="a">
              <li className="mgTtm5">
                {t("vaultForm.steps.5")}{" "}
                <span className="bold">{t("vaultForm.access")}</span>
              </li>
              <li>
                {t("vaultForm.steps.6")}{" "}
                <span className="purple-flag font-12">
                  {t("vaultForm.userpass")}
                </span>
              </li>
            </ol>
          </li>
          <li>
            {t("vaultForm.steps.3")}{" "}
            <span className="bold">{t("vaultForm.user")}</span>
          </li>
          <li>{t("vaultForm.steps.7")}</li>
        </ol>
      </div>

      <Form
        {...layout}
        name="vault-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Vault Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.url) {
            setUrl(changedValues.url);
          }
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          className="vault-item-column"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label={
            <>
              Vault Endpoint
              <br />
              <span className="vault-endpoint-example">
                Example: https://example.url.com or http://12.123.123.1:1234
              </span>
            </>
          }
          name="url"
          rules={getValidationRules(url)}
          className="vault-item-column"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
          ]}
          className="vault-item-column"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
          className="vault-item-column"
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default VaultForm;
