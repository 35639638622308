
import React, { useState } from 'react';
import {  Button, Checkbox, Popover } from 'antd';
import { ReactComponent as DownArrow } from '../../Images/general_icons/down-arrow.svg'
import SpinnerLoader from '../spinnerLoader/spinnerLoader';

import './appPopoverSelect.scss'

const AppPopoverSelect = ({ label, options = [], checkedOptions = [], onSubmit, loading = false }) => {
  const [clicked, setClicked] = useState(false);

  const handleClickChange = (open) => {
    setClicked(open);
  };

  return (
    <Popover
      content={
        <div className="AppPopoverSelect_content">
          <h5>{label}</h5>
          <Checkbox.Group className='AppPopoverSelect_content-list' options={options} onChange={values => onSubmit(values)} value={checkedOptions}  />
        </div>
      }
      trigger="click"
      placement="bottomLeft"
      visible={clicked}
      onVisibleChange={handleClickChange}
    >
      <Button className='AppPopoverSelect__button row g8'>
        <span className="AppPopoverSelect__button-label">{label}</span>
        {loading ? <SpinnerLoader customStyle={{ fontSize: '10px' }} />
        : checkedOptions.length > 0 && <span className='AppPopoverSelect__button-count'>{checkedOptions.length}</span>}
        <DownArrow />
      </Button>
    </Popover>
  );
}

export default AppPopoverSelect;
